.boxMoney{
    box-shadow: 0px -7px 0px #ab2ce2, 0px 7px 0px #ab2ce2 !important;
    border-radius: 20px !important;
    top: 73px;
    
}

.moneyCard {
    position: absolute;
    top: 20%;
    bottom: 0;
    right: 0;
    left: 0;

}
.info-icon{
  width: 20px !important;
}

.countryDrp{
  position: relative !important;

 
}

.ReactFlagsSelect-module_selectBtn__19wW7{
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  color: #000 !important;
  font-size: 12px !important;
}
.ReactFlagsSelect-module_label__27pw9, .ReactFlagsSelect-module_secondaryLabel__37t1D{
  font-size: 16px !important;
}
.ReactFlagsSelect-module_selectOptions__3LNBJ{
  width: 200px !important;
  /* max-width: 125px !important; */
}
.drp11{
  position: absolute !important;
  right: 0;
  z-index: 9999;
  border: 0 !important;
  margin-top: 1%;
  margin-right: 0%;
}

.drp12{
  position: absolute !important;
  right: 0;
  // z-index: 9999;
  border: 0 !important;
  margin-top: 1%;
  margin-right: 0%;
}

.smallText{
  font-size: 16px !important;
}
.bgSend{
  background: #ab2ce2 !important;
  border-radius: 20px !important;
  border: 0 !important;
}

.ReactFlagZIndex{
  z-index: 300  !important;
}