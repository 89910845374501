.step4Welcome{
    background: #1d90cb !important;
    border-radius: 0px 100px 100px 0px;
    width: 100% ;
    max-width: 40%;
}

.formControlStep3{
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
}
.input-container {
	padding-bottom: 1em;
}
.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: 43px !important; 
}

.mainStep3Select{
    border: 1px solid #F3852D !important;
    border-radius: 20px !important;
}

select:focus{
    outline: none !important;
    border: 0;
}

.left-inner-addon .mainStep3Select {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
    padding-left: 43px !important; 
}

.left-inner-addon .mainStep3SelectOrange {
    border: 1px solid #F3852D !important;
    border-radius: 20px !important;
    padding-left: 43px !important; 
}

.left-inner-addon .mainStep3 {
    position: absolute;
    padding: 16px 18px;
    pointer-events: none;
// z-index: 3;
}

.OccupationSelect{
    position: relative;
}
 .OccupationSelect .OccupationIcon{
    position: absolute;
    padding: 16px 18px;
    pointer-events: none;
    z-index: 99999;
}

// .OccupationSelect .jj{
//     // padding-left: 43px !important; 
//     // z-index: 0 !important;
// }


.terms { 
    font-size: 14px ;
    line-height: 2;
    /* or 23px */
    font-weight: 400 !important;
    
    text-align: center;
    
    color: #000000;
}


.right-inner-addon {
    position: relative;
}
.right-inner-addon input {
    padding-right: 35px !important;
}
.right-inner-addon .mainStep3 {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}

.left-and-right-inner-addon {
    position: relative;
}
.left-and-right-inner-addon input {
    padding-right: 35px !important;
    padding-left: 35px !important;
}
.left-and-right-inner-addon .mainStep3.left {
    position: absolute;
    padding: 12px 12px;
    pointer-events: none;
}
.left-and-right-inner-addon .mainStep3.right {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}


.right-inner-addon-b {
    position: relative;
}
.right-inner-addon-b input {
    padding-right: 35px !important;
}
.right-inner-addon-b .mainStep3 {
    position: absolute;
    right: 0px;
    padding: 9px 12px;
    pointer-events: none;
}

input {
    width: 100%;
		
		margin: 0em !important;
		box-sizing: border-box;
}

.mainStep3Drps1{
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
    padding: 6px 18px;
}
.customRadioOrange{
    width: 24px !important;
    height: 24px !important;
}

.specifyColor {
    accent-color: chocolate!important;
 }



.verifyModalbtn4{
    background: #0484C5 !important;
  border-radius: 30px !important;
  border: 0 !important;
  }

//   .switchI input[type=checkbox]:checked + label {
//     background: #c9d845;
// }

.switchI {
    display: inline-flex;
    margin: 0 10px;
  }
  
  .switchI input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .switchI input[type=checkbox]:checked + label {
    background: #0484C5;
  }
  
  .switchI input[type=checkbox]:checked + label::after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }
  
  .switchI label {
    cursor: pointer;
    width: 48px;
    height: 24px;
    background: grey;
    display: block;
    border-radius: 24px;
    position: relative;
  }
  
  .switchI label::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 16px;
    transition: 0.3s;
  }

  .smalllabel{
    font-size: 10px !important;
  }

  .nationalityMain3 input {
    position: relative !important;
}


.nationalityDRP .nationalityMain3 {
    position: absolute !important;
    padding: 16px 18px !important;
    pointer-events: none !important;
// z-index: 3;

}
.SelectValueSelect4 .css-13cymwt-control {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
  }
  
  .SelectValueSelect4 .css-t3ipsp-control {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
  }
  
  .SelectValueSelect4 .css-t3ipsp-control:hover {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
  }

  .SelectValueSelect99{
    // border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
  }
  

  .gggBorder{
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
  }