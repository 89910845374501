.abtPage{
    background-image: url("../../assets/images/bg-banner.svg");
    /* center and scale the image */
    background-repeat: no-repeat;
    background-size: cover;
    // padding-bottom: 12vh;
}

.purpleBox{
    background: #F7EAFC;
    border-radius: 20px;
}

.rhombus {
    padding:30px 50px 30px 30px;
    background-color: #AA2AE1;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    border-top-right-radius: 0 !important;
    border-radius: 20px;
  }

  
  .liList{
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 28px !important;
  }
  
  .contactD{
    font-size: 16px;
  }


.snip1519 {
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border-top: 5px solid #AA2AE1;
  color: #9e9e9e;
  text-align: center;
  font-size: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.snip1519 *,
.snip1519 *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.snip1519 figcaption {
  padding: 12% 7% 14%;
}

.snip1519 i {
  font-size: 54px;
  color: #fff;
  width: 80px;
  line-height: 80px;
  background-color: #AA2AE1;
  border-radius: 50%;
  padding: 0;
  margin: 0 0 10px;
  text-align: center;
  display: inline-block;
}

.snip1519 h3 {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin: 10px 0;
}

.snip1519 p {
  font-size: 0.9em;
  font-weight: 300;
  margin: 0 0 20px;
}

.snip1519 .button {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
}



.snip1519 .button:hover {
  color: #fff;
}

.snip1519 .button:hover:before {
  top: 0;
  opacity: 1;
}

