.notifSec{
    height: 100vh;
}

.notify-box{
    border: 1px solid #EEEAF5;
border-radius: 8px;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background: #F7EAFC !important;
border-radius: 50% !important;
}

.BlankNotificationHeight{
    height: 40vh;
}

.badgeText{
    font-size: 12px !important;
}