
header.top-bar h1 {
    font-family: "Montserrat", sans-serif;
  }
  
  main {
    margin-top: 4rem;
    min-height: calc(100vh - 107px);
  }
  main .inner-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  table.invoice {
    background: #fff;
  }
  table.invoice .num {
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 0.8em;
  }
  table.invoice tr, table.invoice td {
    background: #fff;
    text-align: left;
    font-weight: 400;
    color: #322d28;
  }
  table.invoice tr.header td img {
    max-width: 300px;
  }
  table.invoice tr.header td h2 {
    text-align: right;
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    font-size: 2rem;
    color: #1779ba;
  }
  table.invoice tr.intro td:nth-child(2) {
    text-align: right;
  }
  table.invoice tr.details > td {
    padding-top: 4rem;
    padding-bottom: 0;
  }
  table.invoice tr.details td.id, table.invoice tr.details td.qty, table.invoice tr.details th.id, table.invoice tr.details th.qty {
    text-align: center;
  }
  table.invoice tr.details td:last-child, table.invoice tr.details th:last-child {
    text-align: right;
  }
  table.invoice tr.details table thead, table.invoice tr.details table tbody {
    position: relative;
  }
  table.invoice tr.details table thead:after, table.invoice tr.details table tbody:after {
    content: "";
    height: 1px;
    position: absolute;
    width: 100%;
    left: 0;
    padding: 20px !important;
    margin-top: -1px;
    background: #c8c3be;
  }
  table.invoice tr.totals td {
    padding-top: 0;
  }
  table.invoice tr.totals table tr td {
    padding-top: 0;
    padding-bottom: 0;
  }
  table.invoice tr.totals table tr td:nth-child(1) {
    font-weight: 500;
  }
  table.invoice tr.totals table tr td:nth-child(2) {
    text-align: right;
    font-weight: 200;
  }
  table.invoice tr.totals table tr:nth-last-child(2) td {
    padding-bottom: 0.5em;
  }
  table.invoice tr.totals table tr:nth-last-child(2) td:last-child {
    position: relative;
  }
  table.invoice tr.totals table tr:nth-last-child(2) td:last-child:after {
    content: "";
    height: 4px;
    width: 110%;
    border-top: 1px solid #1779ba;
    border-bottom: 1px solid #1779ba;
    position: relative;
    right: 0;
    bottom: -0.575rem;
    display: block;
  }
  table.invoice tr.totals table tr.total td {
    font-size: 1.2em;
    padding-top: 0.5em;
    font-weight: 700;
  }
  table.invoice tr.totals table tr.total td:last-child {
    font-weight: 700;
  }
  
  .additional-info h5 {
    /* font-size: 0.8em; */
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 2px;
    color: #1779ba;
  }