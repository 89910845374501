.footer-main{
    position: relative;
    background: linear-gradient(114.27deg, #36A7E9 -11.51%, #38B6FF 119.45%) !important;
    padding-top: 220px;
}


.hrFooter{
    width: 100% !important;
    max-width: 86% !important;
}
.getInTouch{
    background: #012B72 !important;
    border-radius: 0 !important;
    width: 100% !important;
    max-width: 55% !important;
}
.footerIcon{
    display: block !important;
    width: 100% !important;
    max-width: 30px !important;
}

.mainInner{
    width: 100%;
}
.subFooter{
    background-image: url("../../../../assets/images/Footer/subFooter.svg") !important;
    background: rgba(23, 97, 167, 1);
    position: absolute;
    z-index: 999;
    right: 0;
    left: 0;
}

.list-group-item {
    background: transparent !important;
    border: 0 !important;
}
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    user-select: none;
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
    display: none;
}

.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}