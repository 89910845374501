.mainLoginSection1{
  background-image: url("../../assets/images/bg-banner.svg");
  /* center and scale the image */
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 12vh;
}

.box .stepsmain ul li {
min-width: 13% !important;
text-align: center !important;
position: relative !important;
}



.mainLoginBox{
  background: #FFFFFF !important;
  box-shadow: 5px 5px 15px rgba(221, 221, 221, 0.3) !important;
  border-radius: 25px !important;
}

.mainBoxStepper{
  display: flex !important;
  align-items: baseline !important;
}

.firstBlockss{
  border-right: 1px solid #D9D9D9 !important;
}

.secondBlock{
border-top: 1px solid #D9D9D9 !important;
z-index: 0;

}


.box .stepsmain ul {
  overflow: hidden;
}
.box .stepsmain ul li div {
  color: #999;
  padding: 10px 10px 10px 10px;
  position: relative;
  width: 100%;
}

.box .stepsmain ul li div span {
  font-size: 13px;
}
// .box .stepsmain ul li:first-child div {
//   width: 135px;
//   padding-left: 15px;
// }
li.active0.done{
  width: 60px !important;
  height: 60px !important;
}

li.active0 {
  width: 60px ;
  height: 60px ;
}

li.active1 {
  width: 60px !important;
  height: 60px !important;
}
li.active2 {
  width: 60px !important;
  height: 60px !important;
}

li.active3 {
  width: 60px !important;
  height: 60px !important;
}
.box .stepsmain ul li.done div {
  border-color: #20a8d8 !important;
  color: #fff !important;
}


/* .box .stepsmain ul li.firstStepactive div::before {
  border: 2px solid #AA2AE1 !important;
  transform: rotate(360deg) !important;
  width: 68px !important;
  content: '';
  position: absolute;
  left: 100%;
} */

.box .stepsmain ul li.firstStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/donePurple.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.box .stepsmain ul li.firstStepactive .step1::after {
  background: #AA2AE1 !important;
 }

 .box .stepsmain ul li.firstStepactive .step3::after {
  background: #AA2AE1 !important;
 }

 .box .stepsmain ul li.secondStepactive div::after {
  background:#AA2AE1;
 }
 .box .stepsmain ul li.thirdStepactive div::after {
  background: #AA2AE1;;
 }
 .box .stepsmain ul li.fourthStepactive div::after {
  background: transparent;
 }
 .box .stepsmain ul li.fifthStepactive div::after {
  background: transparent;
 }

 /* .box .stepsmain ul li.sixthStepactive .step1::after {
  background: transparent !important;
 } */
 .box .stepsmain ul li.fifthStepactive .step1::after {
  display: none !important;
 }
 /* .box .stepsmain #sixxsteps ul li .step1 ::after{
  background: transparent !important;
 } */

 .box .stepsmain ul li.fifthStepremain .step1::after {
  content: " ";
  position: absolute;
  top: 28px;
  left: 50%;
  z-index: 0;
  width: 100%;
  height: 4px;
  background: transparent !important;
  transform: translate(30px, 0px);
}

 .box .stepsmain ul li.finalStepactive div::after {
  background: transparent !important;
 }

//   .box .stepsmain ul li div::after {
//     content: " ";
//     position: absolute;
//     top: 28px;
//     left: 50%;
//     z-index: 0;
//     width: 100%;
//     height: 4px;
//     background: #e2e5ec;
//     transform: translate(30px, 0px);
// }


// .box .stepsmain ul li .step3:after {
// width: calc(100% - 38px);
// }

.box .stepsmain ul li .step3:after, .box .stepsmain ul li div:after {
content: " ";
position: absolute;
top: 28px;
left: 50%;
z-index: 0;
height: 4px;
background: #e2e5ec;
-webkit-transform: translate(30px);
padding: 0px 18px;
transform: translate(30px);
}

.box .stepsmain ul li.finalStepremain div::after {
  content: " ";
  position: absolute;
  top: 28px;
  left: 50%;
  z-index: 0;
  width: 100%;
  height: 4px;
  background: transparent;
  transform: translate(30px, 0px);
}

.box .stepsmain ul li .step1:after, .box .stepsmain ul li div:after {
content: " ";
position: absolute;
top: 28px;
left: 50%;
z-index: 0;
height: 4px;
background: #e2e5ec;
-webkit-transform: translate(30px);
transform: translate(30px);
}


.box .stepsmain ul li.secondStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/moneyDone2.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.box .stepsmain ul li.thirdStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/moneyDone4.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.box .stepsmain ul li.fourthStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/moneyDone5.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.box .stepsmain ul li.fifthStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/moneyDone5.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.box .stepsmain ul li.sixthStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/doneMaroon.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.box .stepsmain ul li.sixthStepactive .step1 {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/doneMaroon.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.box .stepsmain ul li.finalStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/doneBlues.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.box .stepsmain ul li.secondStepremain div { 
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/money2.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}


.box .stepsmain ul li.thirdStepremain div { 
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/money4.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}





.box .stepsmain ul li.fourthStepremain div { 
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/money5.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}

// .box .stepsSendMoney ul li.fourthStepremain .step3::after {
//   content: " ";
//   position: absolute;
//   top: 28px;
//   left: 50%;
//   z-index: 0;
//   width: 100%;
//   height: 4px;
//   background: #e2e5ec;
//   transform: translate(30px, 0px);
// }

.box .stepsmain ul li.fifthStepremain div { 
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/money5.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.box .stepsmain ul li.sixthStepremain div { 
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/maroonInactive.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.box .stepsmain ul li.finalStepremain div { 
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/bluesInactive.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}


.box .stepsmain ul li.active div::after {
  border-left: 30px solid #167495;
}


.box .btn-component {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.backButton{
  background: transparent;
  border: 0;
  color: #01A16F;
  font-weight: medium;
}

.verifyButton{
  background: transparent;
  border: 2px solid #bd4a89;
  border-radius: 30px;
  color: #bd4a89;
  text-transform: uppercase;
  font-weight: bold;
}

.nextButton1{
  background: #AA2AE1;
  border: 2px solid #AA2AE1;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  
}

.nextButton2{
  background: #AA2AE1;
  border: 2px solid #AA2AE1;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.nextButton3{
  background: #AA2AE1;
  border: 2px solid #AA2AE1;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.nextButton4{
  background: #AA2AE1;
  border: 2px solid #AA2AE1;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.nextButtonStep5{
  background: #c3d431 !important;
  border: 2px solid #c3d431 !important;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.nextButtonStep6{
  background: #bd4a89 !important;
  border: 2px solid #bd4a89 !important;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.nextButtonStep7{
  background: #1db9ea !important;
  border: 2px solid #1db9ea !important;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.verifyButton3{
  background: transparent;
  border: 2px solid #F3852D;
  border-radius: 30px;
  color: #F3852D;
  text-transform: uppercase;
  font-weight: bold;
}

.skipButton4{
  background: transparent;
  border: 2px solid #167495 !important;
  border-radius: 30px;
  color: #167495 !important;
  text-transform: uppercase;
  font-weight: bold;
}

.verifyButton1{
  background: transparent;
  border: 2px solid #c3d431 !important;
  border-radius: 30px;
  color: #c3d431 !important;
  text-transform: uppercase;
  font-weight: bold;
}

.fourthskip{
  background: transparent;
  border: 2px solid #0484C5 !important;
  border-radius: 30px;
  color: #0484C5 !important;
  text-transform: uppercase;
  font-weight: bold;
}

.sixskip{
  background: transparent;
  border: 2px solid #bd4a89 !important;
  border-radius: 30px;
  color: #bd4a89 !important;
  text-transform: uppercase;
  font-weight: bold;
}

.backButton3 {
  background: transparent !important;
  border: 0 !important;
  color: #F3852D !important;
}


.nextButtonStep4{
  background: #0484C5 !important;
  border: 2px solid #0484C5 !important;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.verifyButton4{
  background: transparent;
  border: 2px solid #0484C5;
  border-radius: 30px;
  color: #0484C5;
  text-transform: uppercase;
  font-weight: bold;
}

.backButton4 {
  background: transparent !important;
  border: 0 !important;
  color: #0484C5 !important;
}
.backButton5 {
  background: transparent !important;
  border: 0 !important;
  color: #c3d431 !important;
}
.backButton6 {
  background: transparent !important;
  border: 0 !important;
  color: #bd4a89 !important;
}
/* .backButton7 {
  background: transparent !important;
  border: 0 !important;
  color: #0484C5 !important;
} */

.backButton7 {
  background: transparent !important;
  border: 0 !important;
  color: #AA2AE1 !important;
  /* font-weight: normal; */
}

.backButton1{
  display: none;
}

//////////////////////////////////////////////////
.box .stepsmain ul li.fourthStepremain ::after {
  content: " ";
  position: absolute;
  top: 28px;
  left: 50%;
  z-index: 0;
  width: 100%;
  height: 4px;
  background: transparent !important;
  transform: translate(30px, 0px);
}
//////////////////////////////////////////////////

.otpModal input{
  border: 1px solid #01A16F ;
  border-radius: 20px ;
  width: 100% ;
  margin: 10px 10px 10px 10px ;
}


.stepLabel{
  font-size: 14px ;
  line-height: 22px !important;
  font-weight: 500 !important;
}

.step7summary{
  /* mar */
}

.accordion-button  {
  width: 100% !important;
  /* opacity: 0.99 !important; */
  color: #fff !important;
  background-color: #b23fe4 !important;
  padding: 10px 10px !important;
  margin: 0px !important;
  font-family: inherit;
  font-size: 16px !important;
}


.accordion-item {
color: var(--bs-accordion-color);
background-color: var(--bs-accordion-bg);
border: 5px solid #b23fe4 !important;
/* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
}

// .accordion1 .accordion-item {
//   color: var(--bs-accordion-color);
//   background-color: var(--bs-accordion-bg);
//   border: 5px solid #3d3d3d;
//   /* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
// }

// button#rfs-btn {
// background: white !important;
// }

.error_message {
font-size: 11px !important;
font-weight: 500 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
position: absolute;
width: 100% !important;
z-index: 999999;
border: 1px solid #bdbbbb;
border-radius: 3px;
background: #ffffff;
margin-top: 8px;
padding: 8px 0;
max-height: 180px;
overflow: auto;
}

.LastButtonPopup {
background: #04B1E8 !important;
border: 2px solid #1db9ea !important;
border-radius: 30px !important;
color: #fff !important;
font-size: 17px !important;
text-transform: uppercase !important;
font-weight: bold !important;
-webkit-border-radius: 30px !important;
-moz-border-radius: 30px !important;
-ms-border-radius: 30px !important;
-o-border-radius: 30px !important;
}

.LastButtonPopup:hover{
background: #04B1E8 !important;
border: 2px solid #1db9ea !important;
border-radius: 30px !important;
color: #fff !important;
font-size: 17px !important;
text-transform: uppercase !important;
font-weight: bold !important;
-webkit-border-radius: 30px !important;
-moz-border-radius: 30px !important;
-ms-border-radius: 30px !important;
-o-border-radius: 30px !important;
}

.sendM {
  position: absolute;
  top: 8%;
  left: 12%;
  padding-bottom: 12px  !important;
}

.modal-warning{
  width: 100vh !important;
  max-width: 70vh !important;
}



.textAt{
  border: 1px solid #AA2AE1 !important;
}


///////////////////New Atep Add///////////////////////

.box .stepsmain ul li.newStepactive div::after {
  background: #AA2AE1;;
 }

.box .stepsmain ul li.newStepactive div {
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/moneyDone3.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.box .stepsmain ul li.newStepremain div { 
  border-color: #167495 !important;
  color: #fff !important;
  background-image: url("../../assets/images/money3.svg");
  width: 60px;
  height: 60px; 
  font-size: 30px !important;
  margin: 0 auto !important; 
  position: initial !important;
  background-repeat: no-repeat;
  background-size: cover;
}
/////////////////////////////////////////////////////


@media (min-width:1100px) and (max-width:1300px) {
  .box .stepsmain ul li .step3:after {
    width: calc(100% - 50px);
}
.box .stepsmain ul li .step3:after, .box .stepsmain ul li div:after {
  padding: 0px 12px;
}
}