// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }
  
//   body {
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//       'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//       sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
  
//   h1,
//   h2,
//   h3,
//   h4,
//   h5 {
//     font-weight: normal;
//   }
  
//   header {
//     padding: 10px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
//   .meeting-title {
//     font-weight: bold;
//     text-align: center;
//     margin-bottom: 60px;
//   }
//   form {
//     max-width: 450px;
//     margin: 15rem auto;
//     box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
//     border-radius: 8px;
//     padding: 20px;
//   }
  
//   input {
//     width: 100%;
//     border: 1px thin rgb(105, 105, 105) transparent;
//     border-radius: 8px;
//     height: 34px;
//     padding: 5px;
//     color: inherit;
//     font-family: inherit;
//   }
  
//   input::placeholder {
//     color: #aaa;
//   }
  
//   .input-container {
//     margin-bottom: 20px;
//   }
  
//   .btn-signin {
//     width: 100%;
//     height: 34px;
//     border: 1px solid transparent;
//     border-radius: 8px;
//     padding: 6px 14px;
//     background-color: #0d72ed;
//     color: white;
//     font-family: inherit;
//     font-size: 16px;
//     cursor: pointer;
//   }
  
//   form h2,
//   .conference-section h2 {
//     margin-bottom: 20px;
//   }
  
//   .btn-danger {
//     border: 1px solid transparent;
//     border-radius: 4px;
//     padding: 6px 14px;
//     background-color: #f44336;
//     color: white;
//     font-family: inherit;
//     font-size: 14px;
//     cursor: pointer;
//   }
  
//   .room-section{
//     background-color: rgb(37, 37, 37);
//     width: 100%;
//     height: auto;
//   }
//   .conference-section {
//     padding: 20px 30px;
//     max-width:1200px;
//     height: 700px;
//     margin: 0 auto;
//     color: white;
//   }
  
//   .conference-section h2 {
//     text-align: center;
//     font-size: 32px;
//     padding-bottom: 10px;
//     border-bottom: 1px solid #546e7a;
//   }
  
//   .peers-container {
//     display: grid;
//     grid-template-columns: repeat(3, minmax(min-content, 1fr));
//     place-items: center;
//     grid-gap: 10px;
//     margin-top: 70px;
//   }
  
  .peer-video {
    height: 400px;
    width: 500px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .local.peer-video {
    transform: scaleX(-1);
  }
  
//   .peer-name {
//     font-size: 14px;
//     text-align: center;
//   }
  
//   .peer-container {
//     padding: 10px;
//   }
  
  
  .main_control {
    display: flex;
    flex-direction: row;
    max-width:100%;
    height: 90px;
    background-color: #1C1E20;
    color: white;
    padding: 10px;
    justify-content: space-between
  }
  
  .main_controls_section {
    flex-direction: row;
    display: flex;
  }
  
  .main_controls_button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 3px 10px;
    min-width: 80px;
    align-items: center;
    justify-content: center;
  }
  
//   .button_name {
//     font-size: 14px;
//     margin-top: 5px;
//   }
  
//   .control-bar > *:not(:first-child) {
//     margin-left: 8px;
//   }
  
//   .btn-control {
//     font-size: 12px;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     border: 2px solid #37474f;
//     width: 64px;
//     height: 64px;
//     border-radius: 50%;
//     text-align: center;
//     background-color: #607d8b;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
//     color: white;
//     cursor: pointer;
//   }
  
//   .hide {
//     display: none;
//   }
.main_control{
  margin-top: 15px;
  background: transparent;
  border:1px solid #AA2AE1;
  border-radius:12px;
}


.main_controls_button{
  color:#AA2AE1;
}