.email-editor-wrapper {
    border: 1px solid #ccc;
    padding: 5px;
  }
  
  .email-editor-content {
    min-height: 300px;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 15px;
  }
  