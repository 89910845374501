.chatSection{
    height: 100vh;
}

.btnTrans{
    border: 1px solid #AA2AE1 !important;
}
.inner-chat-sec{
    background: #CCECFF; 
}

.inner-chat-sec-recieve{
    background: #F9EFFC;
}

.sendBtn{
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    max-width: 50px !important;
    max-height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sendBtn1{
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileINP{
    position: absolute;
    top: 30%;
    left: 15px;
    z-index: 2;
}

.mainChatPreview{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.mainChatPreviewContainer{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.vector-main{
    position: relative;
    filter: invert(1);
    top: 25px;
    z-index: 2;
    left: 85px;
}

.previewMain{
    position: relative;
}

.OverFlow{
    overflow: auto;
}



.content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
  }
  
  .content .content-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .content:hover .content-overlay{
    opacity: 1;
  }
  
//   .content-image{
//     width: 100%;
//   }
  
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .content:hover .content-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }

  .fadeIn-bottom{
    top: 80%;
  }

  .content-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }

  .ScrollChatScreen{
    overflow-y: auto;
    height: 74vh;
  }

  
  .ScrollChatScreen1{
    overflow-y: auto;
    height: 100vh;
    max-height: 55vh;
  }

  .left-inner-addon textarea{
    padding-left: 43px !important;
  }