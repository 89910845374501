











.mainInternational{
        background-image: url("../../../images/bgInternational.svg");
        height: 100%;
        background-blend-mode: multiply;
        /* center and scale the image */
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 4%;
}   



.btn_standard {
        position: relative;
        background: -webkit-linear-gradient(top, black 50%, #F37A1F 50%) 0 0/200% 200%;
        background: linear-gradient(to bottom, black 50%, #F37A1F 50%) 0 0/200% 200%;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
        -webkit-animation: bg-out 400ms both;
        animation: bg-out 400ms both;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        text-align: left;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 0;
        color: white;
        font-family: "STIHL-Contraface-Display-Title", "Arial Black", sans-serif;
        line-height: 1.1;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        font-size: 14px;
        height: 60px;
        width: 100%;
      }
      
      .btn_standard.active, .btn_standard:hover {
        -webkit-animation: bg-in 400ms both;
        animation: bg-in 400ms both;
      }
      
      .btn_standard:disabled {
        background: #DADADA;
        border-color: #DADADA;
      }
      
      @media only screen and (max-width: 1023px) {
        .btn_standard {
          width: 100%;
        }
      }
      .btn_standard .btn-inner {
        margin: 0 20px;
      }
      
      @media only screen and (min-width: 530px) and (max-width: 767px) {
        .btn_standard:not(.full) {
          min-width: 40.83333px;
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .btn_standard:not(.full) {
          min-width: 121.33333px;
        }
      }
      @media only screen and (min-width: 1024px) {
        .btn_standard:not(.full) {
          min-width: 246px;
        }
      }
      @-webkit-keyframes bg-in {
        from {
          background-position: 0 0;
        }
        to {
          background-position: 0 100%;
        }
      }
      @keyframes bg-in {
        from {
          background-position: 0 0;
        }
        to {
          background-position: 0 100%;
        }
      }
      @-webkit-keyframes bg-out {
        from {
          background-position: 0 100%;
        }
        to {
          background-position: 0 200%;
        }
      }
      @keyframes bg-out {
        from {
          background-position: 0 100%;
        }
        to {
          background-position: 0 200%;
        }
      }
      .maut_button-appstore {
        padding: 15px 40px;
        background-color: #000;
        border: 0;
        box-sizing: border-box;
        width: 240px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    
