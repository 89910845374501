.deviceMain{
    // background: azure;
}

.form-select-zz{
    
}

.word-break {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  
  .cls11{
    padding: 9px 0px 4px 9px !important;
  }