.paymentMethodLogo{
    width: 40% !important;
}

.Image_TableData{
width: 12%;
}

.AddButton{
    border-radius:20px !important;
}