

.firstMoneyBox{

    border-radius: 30px;
    border: 1px solid #EFD7F9 !important;
  }

.custom_send_money_error{
  border: 1px solid red !important;
}
  .secondMoneyBox{
    background: #AA2AE1 !important;

  }
  .countryFlag{
    font-size: 40px !important;
  }

  .select-country{
    width: 100px !important;
    max-width: 80px !important;
    border: 0 !important;
    background: transparent !important;
  }
  .select-country {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='silver'><polygon points='0,0 8,0 4,4'/></svg>") no-repeat scroll 95% 60% transparent !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.PromoCodeBox{
  border: 2px dotted;
}

  .select-country:focus{
    box-shadow: none !important;
    outline: none !important;
  }

  #input-group-dropdown-2{
    width: 100% !important;
    max-width: 20% !important;
    border: 0 !important;
    background: transparent !important;
    color: #000 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
#input-group-dropdown-2:focus{
    outline: none !important;
    border: 0 !important;
    box-shadow:none !important;
    font-weight: bold;
}

  .textInputMoney{

  }

  .mainMoneyBox{
    background: rgba(170, 42, 225, 0.1);
    margin: 0px 30px 0px 30px;
    border-radius: 30px;
  }

  .excText{
    font-size: 17px ;
  }

  .bottomBg{
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

  }
  .cardActivePurple :hover{
    background: #F7EAFC ;
    border-radius: 20px;
  }

  .cardActiveSendMoney{
    background: #F7EAFC !important;
    border-radius: 20px;
  }
  .modal-money{
    width: 100vh !important;
    max-width: 90vh !important;
  }
  .modal-money-payment{
    // height: 100vh !important;
    // max-height: 64vh !important;
  }

  .nextBtn1{
    background: #AA2AE1 !important;
    border-radius: 20px !important;
    border: 0 !important;
    font-weight: bold !important;
  }

  .iconImage{
    position: absolute;

  }
  .right-inner-addon {
    position: relative;
}
.right-inner-addon input {
    padding-right: 35px !important;
}
.right-inner-addon .searchMain {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}
  .right-inner-addon-b {
    position: relative;
}
.right-inner-addon-b input {
    padding-right: 35px !important;
}
.right-inner-addon-b .searchMain {
    position: absolute;
    right: 0px;
    padding: 9px 12px;
    pointer-events: none;
}
.purpleTextV{
    border: 1px solid #AA2AE1 !important;
}

.activeCardR{
    background: #EFD7F9 !important;
    border-radius: 20px;
}

.modalrRec{
    // width: 100vh !important;
    // max-width: 100vh !important;
    border-radius: 20px !important;
}
.innerIconRecActive{
    background: #EFD7F9;
    border-radius: 20px;
}

.modalrRec3{
    border-radius: 20px !important;

}

.mainss{
  // height: 100vh !important;
  // max-height: 74vh !important;
}


.dropdown-btn {
  border-radius: 20px !important;
  background: #EFD7F9 !important;
  width: auto;
  color: #000 !important;
  border: 0 !important;
  // width: 100vh !important;
  // max-width: 43vh !important;
  padding: 12px 12px !important;
  cursor: pointer;
}
.dropdown-btn:hover,
.dropdown-btn:focus {
  background: #f2f2f2;
}

.dropdown-items {
  border-radius: 20px !important;
  background: #EFD7F9 !important;
  // width: 100vh !important;
  // max-width: 42vh !important;
  position: absolute;
  margin-top: 3px;
  display: flex;
  font-size: 15px !important;
  flex-direction: column;
  padding: 25px 33px ;
  // margin-left: 10px;
  z-index: 99;
}
.dropdown-item {
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}
.dropdown-item:not(first-child) {
  padding-top: 15px;
  padding-bottom: 15px;
}
.dropdown-item:not(last-child) {
  padding-top: 15px;
  padding-bottom: 15px;
}

.isVisible {
  visibility: visible;
}
.isHidden {
  visibility: hidden;
}

.mainD{
  color: #8e8192
}

.left-inner-addon .main{
  left:13px !important;
}



.left-inner-addon .mainStep4{
  left: 13px !important;
}

.radio input[type=radio] {
  position: absolute;
  opacity: 0;
}
.radio input[type=radio] + .radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type=radio]:checked + .radio-label:before {
  background-color: #AA2AE1;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type=radio]:focus + .radio-label:before {
  outline: none;
  border-color: #AA2AE1;
}
.radio input[type=radio]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type=radio] + .radio-label:empty:before {
  margin-right: 0;
}

.svg-inline--fa {
  display: inline-block !important;
}

// .svg-inline--fa {
//   display: inline-block !important;
// }

.navMain .nav-link.active, .navMain .show > .nav-link{
  background-color: #F7EAFC !important;
  border-radius: 20px;
}

@media (min-width:1200px) and (max-width:1300px) {
  .nextBtn1 {
    width: 100vh !important;
    max-width: 50vh !important;
}.dropdown-btn{
  max-width: 50vh !important;
}
.dropdown-items{
  max-width: 50vh !important;
}
}
