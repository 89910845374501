.file-input-container {
    position: relative;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .file-input-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f4f7f6;
    cursor: pointer;
    color: #aa2ae1 !important
  }
  
  .inputMobilecode {
    width: 10% !important;
}
.inputMobilenum{
  width:90% !important;
  padding-left: 0px !important;
}

.err-border{
  border: 2px solid red !important;
}

.CancleBUtton{
  border: 1px solid red;
    border-radius: 20px;
    background: red;
    color: white !important;
}

.ProfileLables{
  // top: -0.595rem;
  // left: 3.5rem;
  z-index: 9;

  position: absolute;
  font-family: inherit;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  line-height: inherit;
  left: 3.5rem;
  top: -0.595rem;
  padding: 0 0.25rem;
  margin-bottom: 0 !important;
  color: #80878e !important;
  background: var(--color-white);
  transition: all 0.3s ease;
}

