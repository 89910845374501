.step6Welcome {
    background: #c35c95;
    border-radius: 0px 100px 100px 0px;
    width: 100% ;
    max-width: 40% ;
}

.step6summary {
    background: #c35c95;
    border: 2px solid #c35c95 !important;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.accordian1 accordion-item accordion-header .button {
    width: 100% !important;
    /* opacity: 0.99 !important; */
    color: #fff !important;
    background-color: #c35c95 !important;
    padding: 10px 10px !important;
    margin: 0px !important;
    font-family: inherit;
    font-size: 16px !important;
}

.accordian1 .accordianItems1 .accordianHeader1 .button {
    background-color: #c35c95 !important;
}

.maroonFormStep6 {
    border: 1px solid #c35c95 !important;
    border-radius: 20px !important;
}

.mainStep6Select {
    border: 1px solid #c35c95 !important;
    border-radius: 20px !important;
    padding-left: 13px !important;
}

.accordian1 .accordion-button {
    width: 100% !important;
    color: #ffffff !important;
    background-color: #c35c95 !important;
    padding: 10px 10px !important;
    margin: 0px !important;
    font-family: inherit;
    font-size: 16px !important;
}

button:active {
    border: none !important;
}

.accordian1 .accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: 5px solid #c35c95 !important;
    /* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
}

/* /////////Agent///////// */
input[name=IndividualDetails] {
    pointer-events: none;
}

/* input[name=AgentBussinessDetails] {
    pointer-events: none;
 } */

input[name=IndividualKYCDetails] {
    pointer-events: none;
}

input[name=IndividualIdDetails] {
    pointer-events: none;
}

/* ////////////////////// */

.savebtn6 {
    background: #c35c95;
    border: 2px solid #c35c95 !important;
    border-radius: 30px;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px !important;
    padding: 0px 15px !important;
}

.canclebtn6 {
    color: #c35c95 !important;
    font-size: 14px !important;

}

.Editbtn6 {
    background: #c35c95;
    border: 2px solid #c35c95 !important;
    border-radius: 30px;
    // width: 15% !important;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px !important;
    padding: 0px 13px !important;
}

.savechangesmodal {
    margin-top: 60px;
}

/* modal
savechangesmodal */
.savemodal .modal.show .modal-dialog {
    padding-top: 140px;
}

// .saveandcanclebutton{
//     display: none;
// }

.disableInput {
    pointer-events: none;
}

.enableInput {
    pointer-events: auto;
}

.disableAccordian {
    pointer-events: none;
    border: none !important;
}

.enableAccordian {
    pointer-events: auto;
    border: none !important;
}

.padding_remove_left {
    padding-left: 10px !important;
}

.left-inner-addon .mainStep6SelectFlag {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
    padding: 6px 12px !important;
}