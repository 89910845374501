.mainLoginSection1{
    background-image: url("../../../assets/images/bg-banner.svg");
    /* center and scale the image */
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 12vh;
}

.box .steps ul li {
  min-width: 13% ;
  text-align: center ;
  position: relative ;
}



.mainLoginBox{
    background: #FFFFFF ;
    box-shadow: 5px 5px 15px rgba(221, 221, 221, 0.3) ;
    border-radius: 25px ;
}

.mainBoxStepper{
    display: flex ;
    align-items: baseline ;
}

.firstBlock{
    border-right: 1px solid #D9D9D9 ;
}

.secondBlock{
  border-top: 1px solid #D9D9D9 ;

}

  
  .box .steps ul {
    overflow: hidden;
  }
  .box .steps ul li div {
    color: #999;
    padding: 10px 10px 10px 10px;
    position: relative;
    width: 100% ;
  }

  .box .steps ul li div span {
    font-size: 13px;
  }
  // .box .steps ul li:first-child div {
  //   width: 135px;
  //   padding-left: 15px;
  // }
  li.active0.done{
    width: 60px ;
    height: 60px ;
  }

  li.active0 {
    width: 60px ;
    height: 60px ;
}

li.active1 {
    width: 60px ;
    height: 60px ;
}
li.active2 {
    width: 60px ;
    height: 60px ;
}

li.active3 {
    width: 60px ;
    height: 60px ;
}
  .box .steps ul li.done div {
    border-color: #20a8d8 ;
    color: #fff ;
  }

  
  /* .box .steps ul li.firstStepactive div::before {
    border: 2px solid #AA2AE1 ;
    transform: rotate(360deg) ;
    width: 68px ;
    content: '';
    position: absolute;
    left: 100%;
} */
  
  .box .steps ul li.firstStepactive div {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/donePurple.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .box .steps ul li.firstStepactive .step1::after {
    background: #AA2AE1 ;
    display: none;
   }

   .box .steps ul li.firstStepactive .step3::after {
    background:transparent ;
   }

   .box .steps ul li.secondStepactive div::after {
    background:#01A16F;
   }

   .box .steps ul li.thirdStepactive div::after {
    background: #F3852D;;
   }

   .box .steps ul li.fourthStepactive div::after {
    background: #0484C5;
   }

   .box .steps ul li.fifthStepactive div::after {
    background: #C3D431;
   }
   
   .box .steps ul li.sixthStepactive div::after {
    background: #BD4A89;
   }
   .box .steps ul li.sixthStepactive .step1::after {
    background: red ;
   }
   .box .steps ul li.sixthStepactive .step1::after {
    background: #BD4A89;
   }
   /* .box .steps #sixxsteps ul li .step1 ::after{
    background: transparent ;
   } */

  //  .box .steps ul li.sixthStepremain .step1::after {
  //   background: #e2e5ec;
  // }

   .box .steps ul li.finalStepactive div::after {
    background: #BD4A89 !important;
   }

//   .box .steps ul li div::after {
//     content: " ";
//     position: absolute;
//     top: 28px;
//     left: 50%;
//     z-index: 0;
//     width: 100%;
//     height: 4px;
//     background: #e2e5ec;
//     transform: translate(30px, 0px);
// }

.box .steps ul li .step1:after {
  width: calc(100% - 26px);
}

.box .steps ul li .step3:after {
  width: calc(100% - 49px);
}

.box .steps ul li .step3:after, .box .steps ul li div:after {
  content: " ";
  position: absolute;
  top: 28px;
  left: -58%;
  z-index: 0;
  height: 4px;
  background: #e2e5ec;
  -webkit-transform: translate(30px);
  transform: translate(30px);
}

.box .steps ul li.finalStepremain div::after {
    background: #e2e5ec;

}

.box .steps ul li .step1:after, .box .steps ul li div:after {
  content: " ";
  position: absolute;
  top: 28px;
  left: -74%;
  z-index: 0;
  height: 4px;
  background: #e2e5ec;
  -webkit-transform: translate(30px);
  transform: translate(30px);
}


  .box .steps ul li.secondStepactive div {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/doneGreen.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .box .steps ul li.thirdStepactive div {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/doneOrange.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .box .steps ul li.fourthStepactive div {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/donealiceBlue.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .box .steps ul li.fifthStepactive div {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/doneRadium.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .box .steps ul li.sixthStepactive div {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/doneMaroon.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .box .steps ul li.sixthStepactive .step1 {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/doneMaroon.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .box .steps ul li.finalStepactive div {
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/doneBlues.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .box .steps ul li.secondStepremain div { 
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/greenDropletInactive.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .box .steps ul li.thirdStepremain div { 
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/orangeDropletInactive.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }



  .box .steps ul li.fourthStepremain div { 
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/blueDropletInactive.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .box .steps ul li.fifthStepremain div { 
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/radiumDropletInactive.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .box .steps ul li.sixthStepremain div { 
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/maroonInactive.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .box .steps ul li.finalStepremain div { 
    border-color: #167495 ;
    color: #fff ;
    background-image: url("../../../assets/images/bluesInactive.svg");
    width: 60px ;
    height: 60px ; 
    font-size: 30px ;
    margin: 0 auto ; 
    position: initial ;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  
  .box .steps ul li.active div::after {
    border-left: 30px solid #167495;
  }
  
 
  .box .btn-component {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .backButton{
    background: transparent;
    border: 0;
    color: #01A16F;
    font-weight: medium;
  }

  .verifyButton{
    background: transparent;
    border: 2px solid #bd4a89;
    border-radius: 30px;
    color: #bd4a89;
    text-transform: uppercase;
    font-weight: bold;
  }

  .nextButtonStep1{
    background: #AA2AE1;
    border: 2px solid #AA2AE1;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }

  .nextButtonStep2{
    background: #01A16F ;
    border: 2px solid #01A16F ;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }

  .emailAndPhoneValidClass {
    background-color: gray;
    border: gray;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }

  .nextButtonStep3{
    background: #f3852d ;
    border: 2px solid #f3852d ;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }

  .nextButtonStep4{
    background: #F3852D ;
    border: 2px solid #F3852D ;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }
  .nextButtonStep5{
    background: #c3d431 ;
    border: 2px solid #c3d431 ;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;

  }
  .nextButtonStep6{
    background: #bd4a89 ;
    border: 2px solid #bd4a89 ;
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }
  .nextButtonStep7{
    background: #1db9ea ;
    border: 2px solid #1db9ea ;
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .verifyButton3{
    background: transparent;
    border: 2px solid #F3852D;
    border-radius: 30px;
    padding: 10px;
    color: #F3852D;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
  }

  .skipButton4{
    background: transparent;
    border: 2px solid #167495 ;
    border-radius: 30px;
    padding: 10px;
    color: #167495 ;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;

  }

  .verifyButton1{
    background: transparent;
    border: 2px solid #c3d431 ;
    border-radius: 30px;
    padding: 10px;
    color: #c3d431 ;
    text-transform: uppercase;
    padding: 10px;
    font-weight: bold;
  }

  .fourthskip{
    background: transparent;
    border: 2px solid #0484C5 ;
    padding: 10px;
    border-radius: 30px;
    color: #0484C5 ;
    text-transform: uppercase;
    padding: 10px;
    font-weight: bold;
  }

  .sixskip{
    background: transparent;
    border: 2px solid #bd4a89 ;
    padding: 10px;
    border-radius: 30px;
    color: #bd4a89 ;
    text-transform: uppercase;
    padding: 10px;
    font-weight: bold;
  }

  .backButton3 {
    background: transparent ;
    border: 0 ;
    padding: 10px;
    color: #F3852D ;
  }


  .nextButtonStep4{
    background: #0484C5 ;
    border: 2px solid #0484C5 ;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    font-weight: bold;
  }

  .verifyButton4{
    background: transparent;
    border: 2px solid #0484C5;
    border-radius: 30px;
    padding: 10px;
    padding: 10px;
    color: #0484C5;
    text-transform: uppercase;
    font-weight: bold;
  }

  .backButton4 {
    background: transparent ;
    padding: 10px;
    border: 0 ;
    padding: 10px;
    color: #0484C5 ;
  }
  .backButton5 {
    background: transparent ;
    border: 0 ;
    padding: 10px;
    color: #c3d431 ;
  }
  .backButton6 {
    background: transparent ;
    border: 0 ;
    padding: 10px;
    color: #bd4a89 ;
  }
  /* .backButton7 {
    background: transparent ;
    border: 0 ;
    color: #0484C5 ;
  } */

  .backButton7 {
    background: transparent ;
    padding: 10px;
    border: 0 ;
    color: #AA2AE1 ;
    /* font-weight: normal; */
  }

  .backButton1{
    display: none;
  }




  .stepLabel{
    font-size: 14px ;
    line-height: 22px ;
    font-weight: 500 ;
  }

  .step7summary{
    /* mar */
  }

 .accordion-button  {
    width: 100% ;
    /* opacity: 0.99 ; */
    color: #fff ;
    background-color: #b23fe4 ;
    padding: 10px 10px ;
    margin: 0px ;
    font-family: inherit;
    font-size: 16px ;
}


.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: 5px solid #b23fe4 ;
  /* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
}

// .accordion1 .accordion-item {
//   color: var(--bs-accordion-color);
//   background-color: var(--bs-accordion-bg);
//   border: 5px solid #3d3d3d;
//   /* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); */
// }

// button#rfs-btn {
//   background: white ;
// }

.error_message {
  font-size: 11px !important;
  font-weight: 500 !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  position: absolute;
  width: 100% ;
  z-index: 999999;
  border: 1px solid #bdbbbb;
  border-radius: 3px;
  background: #ffffff;
  margin-top: 8px;
  padding: 8px 0;
  max-height: 180px;
  overflow: auto;
}

.LastButtonPopup {
  background: #04B1E8 ;
  border: 2px solid #1db9ea ;
  border-radius: 30px ;
  color: #fff ;
  font-size: 17px ;
  text-transform: uppercase ;
  font-weight: bold ;
  -webkit-border-radius: 30px ;
  -moz-border-radius: 30px ;
  -ms-border-radius: 30px ;
  -o-border-radius: 30px ;
}

.LastButtonPopup:hover{
  background: #04B1E8 ;
  border: 2px solid #1db9ea ;
  border-radius: 30px ;
  color: #fff ;
  font-size: 17px ;
  text-transform: uppercase ;
  font-weight: bold ;
  -webkit-border-radius: 30px ;
  -moz-border-radius: 30px ;
  -ms-border-radius: 30px ;
  -o-border-radius: 30px ;
}