

.customTh{
    background: #F4F0FF !important;
    border: 0 !important;
    text-align: center !important;
    border-left: 1px solid #EEEAF5 !important;
    border-right: 1px solid #EEEAF5 !important;
}
.item-stock{
  font-size: 10px !important;
}

.customTr{
    /* // border-left: 1px solid #EEEAF5 !important; */
    border: 1px solid #EEEAF5 !important;
}

.dateIcon{
    color: #0484C5;
}

.purpleBorder{
    border: 1px solid #AA2AE1 !important;
}

.callout11{
    width: 100vh;
    max-width: 100vh;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.StatementLables{
  z-index: 9;
  position: absolute;
  font-family: inherit;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  line-height: inherit;
  left: 1.5rem;
  top: -0.595rem;
  padding: 0 0.25rem;
  margin-bottom: 0 !important;
  color: #80878e !important;
  background: var(--color-white);
  transition: all 0.3s ease;
}

.topbottomborder{
  border-top: 2px solid;
  border-bottom: 2px solid;
}

@page {
    size: A4;
    margin: 0;
  }
  
.invoice-container11{
    position: relative;
    /* width: 210mm;
    height: 297mm;
    max-width: 210mm !important; */
    display: flex;
    flex-direction: column;
    margin: auto;
}

.watermark {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2; /* Adjust the opacity as needed */
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .watermark img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .moneyHead{

    font-size: 19px;

  }