.input-with-logo {
    position: relative;
}

.logo-icon {
    width: 70px;
    /* Adjust the width of the logo icon */
    height: 30px;
    /* Adjust the height of the logo icon */
    position: absolute;
    top: 50%;
    /* Vertically center the logo icon */
    transform: translateY(-50%);
    right: 10px;
    /* Adjust the distance from the right edge */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.drop-zone {
    border: 2px dashed #aaa;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.preview-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.preview-item {
    max-width: 150px;
    margin: 10px;
}

.preview-item img {
    max-width: 100%;
    height: auto;
}

#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.slectedImg {
    // maxWidth: '100%', maxHeight: '200px';
    max-width: 100%;
    max-height: 200px;
    margin-top: 40px;
}
.uploaded-file{
    text-align: center;
}