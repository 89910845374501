
.tooltip-container {
  position: relative;
}

.tooltip-button {
//  background-color: #00DBDE;
//  background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);
  border: none;
  color: #fff;
  // padding: 12px 20px;
  font-size: 60px;
  cursor: pointer;
  border-radius: 50px;
  // transition: background 0.3s ease, transform 0.3s ease;
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

// .tooltip-button:hover {
//  background-color: #00DBDE;
//  background-image: linear-gradient(90deg,#FC00FF 0%, #00DBDE 100%);
// transform: translateY(-2px);
// }
.tooltip-content {
  position: absolute;
  background-color: rgba(97, 97, 97, 0.92);
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  font-size: 11px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  top: 50%; /* Align the top of the tooltip with the button's center */
  left: calc(100% + 12px); /* Position the tooltip on the right side */
  transform: translateY(-50%); /* Center vertically */
  white-space: normal;
  width: 200px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.tooltip-container:hover .tooltip-content {
  opacity: 1;
  visibility: visible;
}
.tooltip-content::before {
  content: "";
  position: absolute;
  border: 6px solid transparent;
  border-left-color: rgba(97, 97, 97, 0.92); /* Adjust the arrow color */
  top: 50%; /* Position the arrow vertically */
  left: -12px; /* Position the arrow on the left side of the tooltip content */
  transform: translateY(-50%) rotate(180deg); /* Rotate the arrow */
}

.coupon-box .radio-icon-div{
  // border-bottom: 1px solid #c2c2c2;
}
.coupon-box .radio-icon-div .radio-icon {
    width: 25px;
    height: 25px;
    display: inline-block;
    float: left;
}
.coupon-box .custom-title span{
  padding: 2px 10px;
  border-width: 2px;  
  border-style: dashed;
}
.radio-icon-div ul {
    list-style: none;
}

.promocodePopupHeight{
  height: 30vh;
}

.PremiumExchangerateNote{
  font-size: 12px;
}

.promocodeInputField{
    background: rgb(237 235 235);
    padding: 10px;
    font-size: 20px;
    color: #555;
    border: none;
    border-radius: 10px 0px 0px 10px;
    outline: none;
}

.promocodeInputButton{
  // padding: 10px;
    background: #AA2AE1;
    color: #fff;
    border: none;
    outline: none;
    font-size: 15px;
    border-radius: 10px;
    // cursor: pointer;
}