.option-label{
    font-size: 16px  !important;
    color: #000 !important;
    /* padding: 0 !important; */
    font-weight: normal !important;
}

.select-text{
    font-size: 17px !important;
    padding: 0 !important;
    color: #000 !important;
    font-weight: normal !important;
}

