

  .firstMoneyBox{

    border-radius: 30px;
    border: 1px solid #EFD7F9 !important;
  }
  .secondMoneyBox{
    background: #AA2AE1 !important;

  }
  .countryFlag{
    font-size: 40px !important;
  }

  .select-country{
    width: 100px !important;
    max-width: 80px !important;
    border: 0 !important;
    background: transparent !important;
  }
  .select-country {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='silver'><polygon points='0,0 8,0 4,4'/></svg>") no-repeat scroll 95% 60% transparent !important;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

  .select-country:focus{
    box-shadow: none !important;
    outline: none !important;
  }

  #input-group-dropdown-2{
    width: 100% !important; 
    max-width: 20% !important;
    border: 0 !important;
    background: transparent !important;
    color: #000 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
#input-group-dropdown-2:focus{
    outline: none !important;
    border: 0 !important;
    box-shadow:none !important;
    font-weight: bold;
}

  .textInputMoney{

  }

  .mainMoneyBox{
    background: rgba(170, 42, 225, 0.1);
    margin: 0px 30px 0px 30px;
    border-radius: 30px;
  }

  .excText{
    font-size: 17px ;
  }

  .bottomBg{
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

  }
  .cardActivePurple :hover{
    background: #F7EAFC ;
    border-radius: 20px;
  }
  .modal-money{
    width: 100vh !important;
    max-width: 90vh !important;
  }
  .modal-money-payment{
    height: 100vh !important;
    max-height: 64vh !important;
    overflow-y: scroll !important;
    padding: 0px 30px 0px 30px ;
  }
 
  .nextBtn1{
    background: #AA2AE1 !important;
    border-radius: 20px !important;
    border: 0 !important;
    font-weight: bold !important;
  }

  .nextButton1{
    background: #AA2AE1;
    border: 2px solid #AA2AE1;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;

  }

  .scrollStep2Main{
  height: 100vh;
    max-height: 40vh;
    overflow-y: auto;
  }

  .menuDelievery{

    font-size: 13px !important;

  }

  .menuBank{
    font-size: 15px !important;
  }