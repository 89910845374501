.Loginheading{
    color: #38B6FF !important;
}

.bgSkyBlue{
    background: #38B6FF;
    border-radius: 0px 100px 100px 0px;
    width: 100% ;
    max-width: 40% ;
}

.logincolor{
    color: #38b6ff !important;
}
.loginformcontrol {
    border: 1px solid #38b6ff !important;
    border-radius: 20px !important;
}
.ForgotPasswordButton {
    background-color: rgb(56, 182, 255) !important;
    border: none !important;
    border-radius: 30px !important;
    color: white !important;
}
.a_link{
    text-decoration: none;
}

.loginavatar{
    padding-top: 30%;
}
.mainLoginSection {
    background-image: url("../../../images/bg-banner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    // padding-bottom: 12vh;
}

.loginpagepadding{
    padding-bottom: 20vh;
}

.mainLoginBox {
    background: #FFFFFF !important;
    box-shadow: 5px 5px 15px rgb(221 221 221 / 30%) !important;
    border-radius: 25px !important;
}
.firstBlock {
    border-right: 1px solid #D9D9D9 !important;
}

.Error_Popup{
    padding: 0%;
}