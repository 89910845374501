.abtPage{
    background-image: url("../../assets/images/bg-banner.svg");
    /* center and scale the image */
    background-repeat: no-repeat;
    background-size: cover;
    // padding-bottom: 12vh;
}

.purpleBox{
    background: #F7EAFC;
    border-radius: 20px;
}

.rhombus {
    padding:30px 50px 30px 30px;
    background-color: #AA2AE1;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    border-top-right-radius: 0 !important;
    border-radius: 20px;
  }

  
  .liList{
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 28px !important;
  }
  
  .contactD{
    font-size: 16px;
  }



.custom-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.custom-counter li {
  display: flex;
  content: counters(decimal, ".") " ";
  margin-bottom: 10px;
}

.custom-counter li::before {
  content: counters(decimal, ".") " ";
  margin-right: 5px;
  font-size: 16px;
  // background-color: rgb(0,200,200);
  color: #000;
  font-weight: 400 !important;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 3px;
}
