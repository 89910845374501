.step5Welcome{
    background: #c9d845 !important;
    border-radius: 0px 100px 100px 0px;
    width: 100% ;
    max-width: 40% ;
}


.purpleFormStep5 {
    border: 1px solid #c9d845 !important;
    border-radius: 20px !important;
}

.left-inner-addon  .RadiusDropList{
  border: 1px solid #c9d845 !important;
  border-radius: 20px !important;
  padding-left: 43px !important; 
}

.mainStep5Select{
    border: 1px solid #c9d845 !important;
    border-radius: 20px !important;
    padding-left: 43px !important; 
}

.switch {
    display: inline-flex;
    margin: 0 10px;
  }
  
  .switch input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .switch input[type=checkbox]:checked + label {
    background: #c9d845;
  }
  
  .switch input[type=checkbox]:checked + label::after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }
  
  .switch label {
    cursor: pointer;
    width: 48px;
    height: 24px;
    background: grey;
    display: block;
    border-radius: 24px;
    position: relative;
  }
  
  .switch label::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 16px;
    transition: 0.3s;
  }


  /* //modal css// */
  
  .verfiyBlock1{
    border-right: 1px solid #D9D9D9 !important;
  }

  .borderHeader{
    border-bottom: 1px solid #D9D9D9 !important;
  }
 

  .customRadioOrange{
    width: 24px !important;
    height: 24px !important;
}

#specifyColor1 {
    accent-color: olivedrab!important ;
 }

#specifyColor2 {
    accent-color: olivedrab!important ;
    
}

.verifyModalbtn{
  background: #c9d845 !important;
border-radius: 30px !important;
border: 0 !important;
}

.verifyModal{
  margin-top: 10px !important;
}

.modal-90w.modal-dialog {
  width: 100vh;
  max-width: 100vh !important;
}

.modal-120w.modal-dialog {
  width: 100vh;
  max-width: 100vh !important;
}

.SelectValueSelect5 .css-13cymwt-control {
  border: 1px solid #c9d845 !important;
  border-radius: 20px !important;
}

.SelectValueSelect5 .css-t3ipsp-control {
  border: 1px solid #c9d845 !important;
  border-radius: 20px !important;
}

.SelectValueSelect5 .css-t3ipsp-control:hover {
  border: 1px solid #c9d845 !important;
  border-radius: 20px !important;
}