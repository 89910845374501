.abtPage{
    background-image: url("../../assets/images/bg-banner.svg");
    /* center and scale the image */
    background-repeat: no-repeat;
    background-size: cover;
    // padding-bottom: 12vh;
}

.purpleBox{
    background: #F7EAFC;
    border-radius: 20px;
}

.rhombus {
    padding:30px 50px 30px 30px;
    background-color: #AA2AE1;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    border-top-right-radius: 0 !important;
    border-radius: 20px;
  }

  
  .liList{
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 28px !important;
  }
  
  .contactD{
    font-size: 16px;
  }

  /* Define the custom styling for the ordered list */
.custom-list {
  list-style-type: decimal; /* Use numbers for list items */
  margin-left: 20px; /* Adjust the indentation */
  padding-left: 0; /* Reset default padding */
}

/* Style for list items */
.custom-list li {
  margin-bottom: 15px; /* Add some spacing between list items */
  font-size: 14px; /* Adjust the font size */
  color: #333; /* Adjust the text color */
}
