/* SearchBar.css */

.search-container {
    display: block;
    margin: 0 auto;
    position: relative;
  }
  
  input#search-bar {
    margin: 0 auto;
    padding: 10px 40px 10px 10px;
    font-size: 1rem;
    border: 1px solid #D0CFCE;
    outline: none;
    width: 100%;
  }
  

  .search-icon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
  .jobTitle{
    font-weight: 600;
    font-size: 16px;
  }

  .jobLocation{
    font-weight: 500;
    font-size: 16px;
  }

  .jobLocation1{
    font-weight: 600;
  }