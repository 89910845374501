.currencySection{
    background: #FCF7FE !important;
    padding-top: 100px !important;
    padding-bottom: 100px ;
}
.recharts-wrapper{
    background: transparent !important;
    border-radius: 20px !important;
}

.plusDiv{
    background: #F7EAFC;
border-radius: 10px;
}
.plusDivActive {
    background: #F7EAFC;
    border-radius: 10px;
    border: 2px solid #AB2CE2;
}
.zIndexR{
    // z-index: 2 !important;
}