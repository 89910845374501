.partner-main{
    background: rgba(170, 42, 225, 0.04) !important;
    padding-bottom: 200px;
    padding-top: 100px;
}

.item11 {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    height: 100px;
    max-height: 100px;
    display: flex;
}

.client-img-main {
    display: block !important;
    width: 100% !important;
    max-width: 60%;
    margin: auto;
}

