.droplet{
    max-width: 136px !important;
}

.droplet1{
    max-width: 130px !important;
}


/* Style the counter cards */
.card1 {
  padding: 5px;
  text-align: center;
  background-color: #fff;
  border-radius:10px;
  cursor:pointer;
}
.card1 .profile{
   border-radius:10px;
   transition: 0.2s;
}
.card1:hover .profile{
  transform:scale(1.4);
  border-top-right-radius:50px;
  border-top-left-radius:50px;
}

.card1 .content{
  width:100%;
  height:100%;
  position:relative;
  overflow:hidden;
}
.card1 .content .back{
  position:absolute;
  width:100%;
  height:100%;
  background-color: #0484C5;
  transition:1s;
  border-radius: 23px;
  z-index:1;
  padding:10px;
}

.card1 .content .backGreen{
  position:absolute;
  width:100%;
  height:100%;
  background-color: #01A16F;;
  transition:1s;
  border-radius: 23px;
  z-index:1;
  padding:10px;
}

.card1 .content .backViolet{
  position:absolute;
  width:100%;
  height:100%;
  background-color: #C3D431;
  transition:1s;
  border-radius: 23px;
  z-index:1;
  padding:10px;
}

.card1 .content .backBlue{
  position:absolute;
  width:100%;
  height:100%;
  background-color: #04B1E8;;;
  transition:1s;
  border-radius: 23px;
  z-index:1;
  padding:10px;
}

.card1 .content .backMaroon{
  position:absolute;
  width:100%;
  height:100%;
  background-color: #BD4A89;;
  transition:1s;
  border-radius: 23px;
  z-index:1;
  padding:10px;
}

.card1 .content .backOrange{
  position:absolute;
  width:100%;
  height:100%;
  background-color: #F3852D;;
  transition:1s;
  border-radius: 23px;
  z-index:1;
  padding:10px;
}

.from-left{
  top:0;
  left:-100%;
}
.card1:hover .content .from-left {
   left:0%;
}

.from-bottom{
  top:100%;
  left:0;
}
.card1:hover .content .from-bottom {
   top:0%;
}

.from-right{
  top:0%;
  right:-100%;
}
.card1:hover .content .from-right {
   right:0;
}

.card1 .content .back h3{

  letter-spacing:2px;
}
.card1 .content .back .tem-img{
  border-radius:100%;
}

.card1 .content .back .des{

  padding:20px 0px;
}
.card1 .content .back .social-icon{
  list-style:none;
  margin:0px;
  padding:0px;
}
.card1 .content .back .social-icon li{
  display:inline-block;
}

.card1 .content .back .social-icon li a{
  display:block;
  background:#333;
  color:#fff;
  width:40px;
  height:40px;
  text-align:center;
  line-height:40px;
  border-radius:100%;
}



.innerTextInner{
  font-size: 14px !important;
}

// new css


.leavesMain{
  display: flex;
  justify-content: space-between;
}

.left-side,
.right-side {
  flex: 1;
}