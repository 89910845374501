.abtPage{
    background-image: url("../../assets/images/bg-banner.svg");
    /* center and scale the image */
    background-repeat: no-repeat;
    background-size: cover;
    // padding-bottom: 12vh;
}

.purpleBox{
    background: #F7EAFC;
    border-radius: 20px;
}

.rhombus {
    padding:30px 50px 30px 30px;
    background-color: #AA2AE1;
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    border-top-right-radius: 0 !important;
    border-radius: 20px;
  }

  
  .liList{
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 28px !important;
  }
  
  .contactD{
    font-size: 16px;
  }



.custom-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.custom-counter li {
  display: flex;
  counter-increment: step-counter;
  margin-bottom: 10px;
}

.custom-counter li::before {
  content: counter(step-counter);
  margin-right: 5px;
  font-size: 16px;
  // background-color: rgb(0,200,200);
  color: #000;
  font-weight: 400 !important;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 3px;
}


/* ReviewListPage.scss */

.testimonial-card {
  padding: 20px;
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.position {
  font-style: italic;
  margin-top: -10px;
  margin-bottom: 20px;
}

.slick-dots {
  text-align: center;
}

.testimonial-slide {
  padding: 40px 20px;
}
.testimonial_box-top {
  background-color: #F7EAFC;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.testimonial_box-icon {
  padding: 10px 0;
}
.testimonial_box-icon i {
  font-size: 25px;
  color: #14213d;
}
.testimonial_box-text {
  padding: 10px 0;
}
.testimonial_box-text p {
  color: #293241;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}
.testimonial_box-img {
  padding: 20px 0 10px;
  display: flex;
  justify-content: center;
}
.testimonial_box-img img {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  border: 2px solid #e5e5e5;
}
.testimonial_box-name {
  padding-top: 10px;
}
.testimonial_box-name h4 {
  font-size: 20px;
  line-height: 25px;
  color: #293241;
  margin-bottom: 0;
}
.testimonial_box-job p {
  color: #293241;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 20px;
  font-weight: 300;
  margin-bottom: 0;
}/* ReviewListPage.scss */

.testimonial_box-top {
  margin-right: 20px !important; /* Add space between cards */
}

/* Remove the margin from the last card in each row */
.testimonial_box-top:nth-child(2n) {
  margin-right: 0 !important;
}

.slick-dots{
  bottom: -42px;
  left: -16px;
}