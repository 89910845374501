.bgPurple{
    background: #AA2AE1;
    border-radius: 0px 100px 100px 0px;
    width: 100%;
    max-width: 40% ;
}

.formControlStep2{
    border: 1px solid #AA2AE1 !important;
    border-radius: 20px !important;
}

.input-container {
	padding-bottom: 1em;
}
.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: 43px !important; 
}

select{
    border: 1px solid #AA2AE1 !important;
    border-radius: 20px !important;
}

select:focus{
    outline: none !important;
    border: 0;
}

.left-inner-addon .purpleBorder  {
    border: 1px solid #AA2AE1 !important;
    border-radius: 20px !important;
    padding-left: 43px !important; 
}

.left-inner-addon .Flagimgset  {
    position: absolute;
    padding: 14px 18px;
    z-index: 11;
    pointer-events: none;
}



.left-inner-addon .main {
    position: absolute;
    padding: 16px 18px !important;
    pointer-events: none;
    z-index: 3;
}


.terms { 
    font-size: 14px ;
    line-height: 2;
    /* or 23px */
    font-weight: 400 !important;
    
    text-align: center;
    
    color: #000000;
}


.right-inner-addon {
    position: relative;
}
.right-inner-addon input {
    padding-right: 35px !important;
}
.right-inner-addon .main {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}

.left-and-right-inner-addon {
    position: relative;
}
.left-and-right-inner-addon input {
    padding-right: 35px !important;
    padding-left: 35px !important;
}
.left-and-right-inner-addon .main.left {
    position: absolute;
    padding: 12px 12px;
    pointer-events: none;
}
.left-and-right-inner-addon .main.right {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}


.right-inner-addon-b {
    position: relative;
}
.right-inner-addon-b input {
    padding-right: 35px !important;
}
.right-inner-addon-b .main {
    position: absolute;
    right: 0px;
    padding: 9px 12px;
    pointer-events: none;
}

input {
    width: 100%;
		
		margin: 0em !important;
		box-sizing: border-box;
}

.mainDrp1{
    border: 1px solid #AA2AE1 !important;
    border-radius: 20px !important;
    padding: 6px 18px;
}

.AU{
    background-image: url('https://flagcdn.com/16x12/au.png');
}

.recaptchaalert{
    border: 5px solid red;
    border-radius: 10px;
  }

//   .recaptchaalert .rc-anchor-normal{
//     height: 0px !important;
//     width: 0px !important;
// }

.DropDownUp{
    z-index: 50;
}

// .recaptcha-container {
//     position: relative;
//   }
  
//   .error-message {
//     color: red;
//     font-size: 12px;
//     margin-top: 5px;
//   }
  

// .SelectValueSelect{
//     padding: 0% !important;
// }

// .SelectValueSelect #react-select-2-input {
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//     margin-left: 0px !important;
// }

// // .SelectValueSelect .css-13cymwt-control {
// //     border: 1px solid #AA2AE1 !important;
// //     border-radius: 20px !important;
// //     // padding-left: 43px !important;
// // }

// .SelectValueSelect .css-t3ipsp-control{
//     border: 1px solid #AA2AE1 !important;
//     border-radius: 20px !important;
//     box-shadow: 0px 0px 0px 1px  #AA2AE1 !important;
// }

// .SelectValueSelect .css-t3ipsp-control:hover{
//     border: 1px solid #AA2AE1 !important;
//     border-radius: 20px !important;
// }

// .SelectValueSelect .css-qbdosj-Input {
//     visibility: visible;
//     -webkit-flex: 1 1 auto;
//     -ms-flex: 1 1 auto;
//     flex: 1 1 auto;
//     display: inline-grid;
//     grid-area: 1/1/2/3;
//     grid-template-columns: 0 min-content;
//     margin: 0px !important; 
//     padding-bottom: 0px !important; 
//     padding-top: 0px !important; 
//     color: hsl(0, 0%, 20%);
//     box-sizing: border-box;
// }

// .SelectValueSelect .css-166bipr-Input {
//     visibility: visible;
//     -webkit-flex: 1 1 auto;
//     -ms-flex: 1 1 auto;
//     flex: 1 1 auto;
//     display: inline-grid;
//     grid-area: 1/1/2/3;
//     grid-template-columns: 0 min-content;
//     margin: 0px !important; 
//     padding-bottom: 0px !important; 
//     padding-top: 0px !important; 
//     color: hsl(0, 0%, 20%);
//     box-sizing: border-box;
// }

// .SelectValueSelect .css-1u9des2-indicatorSeparator{
//     background-color: #d6d6d6 !important;
// }

// .SelectValueSelect .css-tj5bde-Svg{
//     color: #AA2AE1 !important;
// } 

//   .SelectHoverLabel{
//         font-family: inherit;
//         font-size: 1rem;
//         line-height: inherit;
//         z-index: 10;
//         resize: none;
//         border-radius: 0.25rem;
//         background: transparent;
//         transition: all 0.2s ease-in-out;
//   }
//   .SelectHoverLabel::-moz-placeholder {
//     opacity: 0;
//     visibility: hidden;
//     color: transparent;
//   }
//   .SelectHoverLabel:-ms-input-placeholder {
//     opacity: 0;
//     visibility: hidden;
//     color: transparent;
//   }
//   .SelectHoverLabel::placeholder {
//     opacity: 0;
//     visibility: hidden;
//     color: transparent;
//   }
//   .SelectHoverLabel:focus {
//     outline: none;
//     border: 2px solid var(--color-blues);
//   }
//   .SelectHoverLabel:focus ~ .form-label1 {
//     top: -0.595rem;
//     left: 3.50rem;
//     z-index: 5;
//     font-size: 0.875rem;
//     font-weight: 500;
//     color: #80878e;
//     transition: all 0.2s ease-in-out;
//   }
//   .SelectHoverLabel:not(:-moz-placeholder-shown).SelectHoverLabel:not(:focus) ~ .form-label1 {
//     top: -0.595rem;
//     left: 3.50rem;
//     z-index: 9;
//     font-size: 0.875rem;
//     font-weight: 500;
//     -moz-transition: all 0.2s ease-in-out;
//     transition: all 0.2s ease-in-out;
//   }
//   .SelectHoverLabel:not(:-ms-input-placeholder).SelectHoverLabel:not(:focus) ~ .form-label1 {
//     top: -0.595rem;
//     left: 3.50rem;
//     z-index: 11;
//     font-size: 0.875rem;
//     font-weight: 500;
//     -ms-transition: all 0.2s ease-in-out;
//     transition: all 0.2s ease-in-out;
//   }
//   .SelectHoverLabel:not(:placeholder-shown).SelectHoverLabel:not(:focus) ~ .form-label1 {
//     top: -0.595rem;
//     left: 3.50rem;
//     z-index: 11;
//     font-size: 0.875rem;
//     font-weight: 500;
//     transition: all 0.2s ease-in-out;
//   }


  