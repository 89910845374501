.cookieBox{
    background: #F7EAFC !important;
    border-radius: 10px;
}

.custom{
    border: 1px solid black !important;
}
.modal-10w{
    width: 100vh;
    max-width: 100vh !important;
    background: #F7EAFC !important;
    border-radius: 13px !important;

}
.headerw-10w{
    background: #F7EAFC !important;

}
.body-10w{
    height: 100%;
    background: #F7EAFC !important;
}
.header-10w1{
    background: #F7EAFC !important;
    border-bottom: 1px solid #ddd !important;
}

.inner-body-cookie{
    height: 100vh !important;
    max-height: 38vh !important;
    overflow-y: scroll !important;
}