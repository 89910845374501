.form-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-section {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-section h2 {
  margin-top: 0;
}

.form-row {
  margin-bottom: 10px;
}

.form-row label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.showEnd {
  display: flex;
  justify-content: flex-end;
}