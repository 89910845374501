.tableRespo1{
    height: 100vh;
    max-height: 70vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;

}
.submitTransactionLogo{
    width: 60px;
    height: 60px !important;
}

.DropDownSize{
    margin : 0px !important;
}