.step7Welcome {
    background: #1db9ea;
    border-radius: 0px 100px 100px 0px;
    width: 100% ;
    max-width: 40% ;
}

.formControlStep4 {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
}

.input-container {
    padding-bottom: 1em;
}

.left-inner-addon {
    position: relative;
}

// .left-inner-addon input {
//     padding-left: 43px !important;
// }

.mainStep4Select {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
}

select:focus {
    outline: none !important;
    border: 0;
}


.left-inner-addon .mainStep4Select {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
    padding-left: 43px !important;
}

.left-inner-addon .mainStep4 {
    position: absolute;
    padding: 16px 18px;
    pointer-events: none;
    z-index: 3 !important;
}

.terms {
    font-size: 14px;
    line-height: 2;
    /* or 23px */
    font-weight: 400 !important;

    text-align: center;

    color: #000000;
}


.right-inner-addon {
    position: relative;
}

.right-inner-addon input {
    padding-right: 35px !important;
}

.right-inner-addon .mainStep4 {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}

.left-and-right-inner-addon {
    position: relative;
}

.left-and-right-inner-addon input {
    padding-right: 35px !important;
    padding-left: 35px !important;
}

.left-and-right-inner-addon .mainStep4.left {
    position: absolute;
    padding: 12px 12px;
    pointer-events: none;
}

.left-and-right-inner-addon .mainStep4.right {
    position: absolute;
    right: 0px;
    padding: 12px 12px;
    pointer-events: none;
}


.right-inner-addon-b {
    position: relative;
}

.right-inner-addon-b input {
    padding-right: 35px !important;
}

.right-inner-addon-b .mainStep4 {
    position: absolute;
    right: 0px;
    padding: 9px 12px;
    pointer-events: none;
}

input {
    width: 100%;
    
    margin: 0em !important;
    box-sizing: border-box;
}

.mainStep4Drps1 {
    border: 1px solid #0484C5 !important;
    border-radius: 20px !important;
    padding: 6px 18px;
}

.customRadioOrange {
    width: 24px !important;
    height: 24px !important;
}

#specifyColor1 {
    accent-color: chocolate !important;
}

#specifyColor2 {
    accent-color: chocolate !important;
}

.custom-file-container {
    max-width: 400px;
    margin: 0 auto;
}

.accordion-item:first-of-type {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.fa-edit {
    padding-top: 10px !important;
}

.step7summary {
    background: #04B1E8;
    border: 2px solid #1db9ea !important;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.edit {
    font-size: 17px !important;
    margin-right: 30px !important;
}

.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    font-size: 16px;
}


.accordion-item:last-of-type {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.accordion-button {
    width: 100% !important;
    /* opacity: 0.99 !important; */
    color: #fff !important;
    background-color: #fff;
    padding: 10px 10px !important;
    margin: 0px !important;
    font-family: inherit;
    font-size: 16px !important;
}



.FieldDeasable {}


/* /////////Bussiness///////// */
input[name=BussinessSevenField] {
    pointer-events: none;
}

input[name=businessdetails] {
    pointer-events: none;
}

input[name=kycdetails] {
    pointer-events: none;
}

input[name=BussinessIdDetails] {
    pointer-events: none;
}

/* /////////////////////// */

/* /////////Agent///////// */
input[name=AgentDetails] {
    pointer-events: none;
}

input[name=AgentBussinessDetails] {
    pointer-events: none;
}

input[name=AgentKYCDetails] {
    pointer-events: none;
}

input[name=AgentIdDetails] {
    pointer-events: none;
}

/* ////////////////////// */

/* name="businessdetails" */
.saveandcancle {
    display: none !important;
}

.editt {
    display: block;
}

.savebtn7 {
    background: #b23fe4;
    border: 2px solid #b23fe4 !important;
    border-radius: 30px;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px !important;
    padding: 0px 15px !important;
}

.canclebtn7 {
    color: #b23fe4 !important;
    font-size: 14px !important;

}

.Editbtn7 {
    background: #b23fe4;
    border: 2px solid #b23fe4 !important;
    border-radius: 30px;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px !important;
    padding: 0px 13px !important;
}

/* .Editbtn7:hover{
    background: #05a8d9;
    font-size: 14px !important;
    padding: 5px 15px !important;
} */

.disableAgentInput {
    pointer-events: none !important;
}

.enableAgentInput {
    pointer-events: auto !important;
}

.disableAgentAccordian {
    pointer-events: none !important;
    border: none !important;
}

.enableAgentAccordian {
    pointer-events: auto !important;
    border: none !important;
}

.disableInput {
    pointer-events: none;
}

.enableInput {
    pointer-events: auto !important;
}

.disableAccordian {
    pointer-events: none;
    border: none !important;
}

.enableAccordian {
    pointer-events: auto;
    border: none !important;
}

.padding_remove_left {
    padding-left: 10px !important;
}

.ImageView{
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
}