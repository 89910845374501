@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



:root {
  --color-black: hsl(0, 0%, 10%);
  --color-grays: hsl(0, 0%, 65%);
  --color-light: hsl(0, 0%, 96%);
  --color-white: hsl(0, 0%, 100%);
  --color-blues: hsl(225, 100%, 60%);
  --shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

body {
  margin: 0 !important;
  margin: 0 !important;
  /* padding-right:  0 !important; */
  /* overflow: inherit !important; */
}

.selectedCell {
  border: 2px solid #aa2ae1;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image-zoom {
  transition: transform 0.2s;
}

.image-zoom:hover {
  transform: scale(3);
  /* Adjust the scale factor as needed for the desired zoom level */
}

.nationality .css-hlgwow {
  padding-left: 32px !important;
}

.hide-border:before,
.hide-border:after {
  display: none;
}

.dd-item::marker {
  display: none !important;
  font-size: 0px;
}


/* .btn-outline-secondary:hover {
  color: transparent !important;
} */

.mainBadge {
  font-size: 0.7rem !important;
}

.rsm-svg {
  height: 100%;
  width: 100%;
}

.modal {
  z-index: 11112 !important;
}

.image-gallery-play-button {
  display: none;
}

.image-gallery {
  width: 50%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px !important;
  width: 60px !important;
}

.card .header .header-dropdown li .dropdown-menu,
.header-dropdown .dropdown-menu {
  background: #17191c;
  border-radius: 0.55rem;
  overflow: hidden;
  transform: none !important;
  left: auto !important;
  right: 10px !important;
  padding: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  border: 0;
  top: 10px !important;
  color: #999 !important;
}

.card .header .header-dropdown li a {
  color: #999 !important;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
}

.card .header .header-dropdown li a:hover {
  color: #fff !important;
}

.accordion .card .card-header {
  background-color: transparent !important;
}

.accordion .card .card-header .btn-link {
  color: #666;
}

.accordion .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}

.button-container {
  display: none !important;
}

.hide-toggle:after {
  display: none !important;
  margin-left: 0em !important;
  vertical-align: 0em !important;
  content: "" !important;
  border-top: 0em solid !important;
  border-right: 0em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0em solid transparent !important;
}

.form-control {
  position: relative;
  background: transparent !important;
  background-color: transparent !important;
  height: calc(1.5em + 0.75rem + 5px);
}

.hide-toggle .btn-outline-secondary {
  height: calc(1.5em + 0.75rem + 5px) !important;
}

.jvectormap-container {
  height: 300px;
}

.jvectormap-zoomin {
  position: absolute;
  top: 0px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}

.jvectormap-zoomout {
  position: absolute;
  top: 27px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}

.list-group-item+.list-group-item {
  border-top-width: 1px !important;
}

.hclass {
  left: -40px !important;
}

.navbar-fixed-top .navbar-brand img {
  width: 85px;
  vertical-align: top;
  margin-top: 2px;
}

.main-logo {
  width: 111px !important;
}

body:before,
body:after {
  height: 0px !important;
  width: 0% !important;
  position: fixed !important;
  content: "" !important;
  z-index: 999 !important;
}

#wrapper:before,
#wrapper:after {
  height: 0 !important;
}

#left-sidebar {
  transition: all .3s ease-in-out !important;
  width: 250px !important;
  height: calc(100vh - 67px) !important;
  float: left !important;
  margin-top: 67px !important;
  position: fixed !important;
  left: 5px;
  background-color: #fff !important;
  overflow-y: auto !important;
}

.theme-purple .sidebar-nav .metismenu>li i {
  color: #fff !important;
}

.sidebar-nav .metismenu a {
  color: #17191c !important;
}

.sidebar-nav .metismenu>li .active a {
  font-weight: 500 !important;
  color: #aa2ae1 !important;
}

.theme-purple .sidebar-nav .metismenu>li .active i {
  color: #aa2ae1 !important;
}

.main-body {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

#pagination {
  margin: 0;
  padding: 0;
  text-align: center;
}

#pagination li {
  display: inline;
}

#pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  color: #000;
}

/* Active and Hoverable Pagination */
#pagination li a {
  border-radius: 5px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

#pagination li a.active {
  background-color: #4caf50;
  color: #fff;
}

#pagination li a:hover:not(.active) {
  background-color: #ddd;
}

/* border-pagination */
.b-pagination-outer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  display: flex;
}

#border-pagination {
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

#border-pagination li {
  display: inline;
}

#border-pagination li a {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ddd;
  float: left;
}

#border-pagination li a {
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

#border-pagination li a.active {
  background-color: #4caf50;
  color: #fff;
}

#border-pagination li a:hover:not(.active) {
  background: #ddd;
}

::marker {
  display: none !important;
}

.navbar-fixed-top {
  top: 0px !important;
}

.purpleBorder {
  border: 1px solid #aa2ae1 !important;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  height: auto;
  display: table;
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.item {
  width: auto;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 8px;
}

.sidebar-nav .metismenu>li .active a {
  font-weight: normal !important;
  border-left: 5px solid !important;
  /* border-left-color: blueviolet !important; */
  background-color: #f1f1f1 !important;
}

.sidebar-nav .metismenu a:hover,
.sidebar-nav .metismenu a:focus,
.sidebar-nav .metismenu a:active {
  text-decoration: none !important;
  background: blueviolet !important;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
input[type=date]{
  display: flex;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(400deg) !important;
  right: -99px !important;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(224deg) !important;
  left: -99px !important;
}

.hide-border:before,
.hide-border:after {
  display: none;
}

.dd-item::marker {
  display: none !important;
  font-size: 0px;
}

/* .btn-outline-secondary:hover {
  color: transparent !important;
} */

.rsm-svg {
  height: 100%;
  width: 100%;
}

.modal {
  z-index: 11112 !important;
}

.image-gallery-play-button {
  display: none;
}

.image-gallery {
  width: 50%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px !important;
  width: 60px !important;
}

.card .header .header-dropdown li .dropdown-menu,
.header-dropdown .dropdown-menu {
  background: #17191c;
  border-radius: 0.55rem;
  overflow: hidden;
  transform: none !important;
  left: auto !important;
  right: 10px !important;
  padding: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  border: 0;
  top: 10px !important;
  color: #999 !important;
}

/* .user:hover{
  background-color:white;
  cursor: pointer;
  transition: all 0.9s;
  transform: scale(1.1);
} */
.card .header .header-dropdown li a {
  color: #999 !important;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
}

/* button#uncontrolled-tab-example-tab-userinfo {
  color: black !important;
}
button#uncontrolled-tab-example-tab-chats {
  color: black !important;
}
button#uncontrolled-tab-example-tab-transactions {
  color: black !important;
}
button#uncontrolled-tab-example-tab-compliance {
  color: black !important;
}
button#uncontrolled-tab-example-tab-rewards {
  color: black !important;
}
button#uncontrolled-tab-example-tab-userinfo.active {
  background-color: ;
} */
.mainUl.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  border: 0;
  border-bottom: 2px solid #aa2ae1 !important;
  border-radius: 0 !important;
  color: #000 !important;
}

.navTabs.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  border: 0;
  border-bottom: 2px solid #aa2ae1 !important;
  border-radius: 0 !important;
  color: #000 !important;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  color: #000;
  border-radius: 0.375rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  color: #000;
  /* border: 0; */
  background-color: #fff;
}

.user-info {
  margin-top: -20px;
  margin-left: -10px;
}

.info {
  margin-top: -20px;
}

.card .header .header-dropdown li a:hover {
  color: #fff !important;
}

.accordion .card .card-header {
  background-color: transparent !important;
}

.accordion .card .card-header .btn-link {
  color: #666;
}

.accordion .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}

.button-container {
  display: none !important;
}

button {
  box-shadow: none !important;
}

.hide-toggle:after {
  display: none !important;
  margin-left: 0em !important;
  vertical-align: 0em !important;
  content: "" !important;
  border-top: 0em solid !important;
  border-right: 0em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0em solid transparent !important;
}

.form-control {
  position: relative;
  background: transparent !important;
  background-color: transparent !important;
  height: calc(1.5em + 0.75rem + 5px);
}

.hide-toggle .btn-outline-secondary {
  height: calc(1.5em + 0.75rem + 5px) !important;
}

.jvectormap-container {
  height: 300px;
}

.jvectormap-zoomin {
  position: absolute;
  top: 0px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}

.jvectormap-zoomout {
  position: absolute;
  top: 27px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}

.list-group-item+.list-group-item {
  border-top-width: 1px !important;
}

.hclass {
  left: -40px !important;
}

.navbar-fixed-top .navbar-brand img {
  width: 85px;
  vertical-align: top;
  margin-top: 2px;
}

.main-logo {
  width: 111px !important;
}

* {
  font-family: "Poppins", sans-serif;
}

html body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px !important;
  /* overflow-x: hidden !important; */
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
  height: 100% !important;
}

/* Cursor Pointer Utility Class */

.pointer {
  cursor: pointer !important;
}

.eventNone {
  pointer-events: none;
}

.liInnerSettings::marker {
  content: '';
}

/* Theme Background Color Utilities Class */

.aliceBlueBackground {
  background: #38b6ff !important;
}

.darkBlueBackground {
  background: #012b72 !important;
}

.purpleBackground {
  background: #aa2ae1 !important;
}

.greenBackground {
  background: #42e352 !important;
}

.purpleShadeBackground {
  background: rgba(171, 44, 226, 0.15) !important;
}

.lightPurpleShadeBackground {
  background: rgba(171, 44, 226, 0.15) !important;
}

/* Theme Text Color Utilities Class  */

.aliceBlueText {
  color: #38b6ff !important;
}

.blackShade {
  color: rgba(0, 0, 0, 0.69) !important;
}

.darkBlueText {
  color: #012b72 !important;
}

.successText {
  color: #01a16f !important;
}

.purpleText {
  color: #aa2ae1 !important;
}

.orangeText {
  color: #f3852d !important;
}

.maroonText {
  color: #bd4a89 !important;
}

.radiumText {
  color: #c9d845 !important;
}

.blueText {
  color: #0484c5 !important;
}

.grayShade {
  color: #999999 !important;
}

.maroonText {
  color: #bd4a89 !important;
}

.radiumText {
  color: #c9d845 !important;
}

/* Bigger Text Utility Class */

h1 {
  color: #000 !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  line-height: 54px !important;
}

/* Medium Text Utility Class */

h2 {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 42px !important;
}

/* Normal Text Utility Class */

h3 {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 28px !important;
  line-height: 42px !important;
}

/* Font Weight Utility Class */

.bolder {
  font-weight: 700 !important;
}

.medium {
  font-weight: 600 !important;
}

.normal {
  font-weight: 500 !important;
}

.simple {
  font-weight: 400 !important;
}

/* Text tags utility class  */

span {
  /* font-size: 20px !important;
  color: #aa2ae1 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important; */
}

/* p {
  font-weight: 400 !important;
  font-size: 20px;
  line-height: 30px !important;
  color: #000 !important;
} */

small {
  font-weight: 400 !important;
  font-size: 0.9em !important;
  line-height: 22px !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

label {
  color: #012b72 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.mainLabel {
  color: #222222 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

/* text transform property */

.upparcase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root button {
  width: auto !important;
  margin: 0 !important;
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
}

.rdrCalendarWrapper button {
  width: auto !important;
  margin: 0 !important;
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
}

/* Common Button Utility Class */

button {
  width: 100% !important;
  opacity: 0.99 !important;
  /* color: #fff !important; */
  padding: 10px 10px !important;
  margin: 10px 10px !important;
  font-family: inherit;
  /* font-size: 16px !important; */
}

.primaryButton {
  background: #aa2ae1 !important;
}

.greenButton {
  background: #01a16f !important;
}

.WhiteButton {
  background: #ffffff !important;
}

.bordergreen {
  border: 2px solid #01a16f !important;
}

.bordergreen:hover {
  border: 2px solid #01a16f !important;
}

.maroonButton {
  background: #bd4a89 !important;
}

.secondaryButton {
  background: #012b72 !important;
  opacity: 0.6 !important;
}

/* Form Control Utility class */

.form-control {
  position: relative;
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #dddddd !important;
  border-radius: 20px !important;
  padding: 24px 12px 24px 12px !important;
}

.form-controll1 {
  position: relative;
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #DDDDDD !important;
  border-radius: 20px 0px !important;
  padding: 24px 12px 24px 12px !important;
  -webkit-border-radius: 20px 0px !important;
  -moz-border-radius: 20px 0px !important;
  -ms-border-radius: 20px 0px !important;
  -o-border-radius: 20px 0px !important;
}

.form-select {
  border: 1px solid #dddddd !important;
  border-radius: 20px !important;
  padding: 12px 12px 12px 12px !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-input {}

.form-check-input[type="checkbox"] {
  border-color: #000 !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
  color: #000 !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input[type="checkbox"]:checked {
  background-color: #003c9e !important;
}

.form-check-input[type="radio"] {
  color: #003c9e !important;
  outline: none !important;
  box-shadow: none !important;
}

/* .form-check-input[type=radio]:checked{
      background-color: #003C9E !important;
    } */

.form-check-input[type="checkbox"]:focus {
  outline: none !important;
}

/* Border radius utility class */

.fullRoundedCorner {
  border-radius: 42.5px !important;
}

.roundedCorner {
  border-radius: 20px !important;
}

/*   */

/* swiper css */

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  max-width: 30%;
}

.swiper-3d .swiper-slide-shadow-right {
  background: transparent !important;
}

.swiper-3d .swiper-slide-shadow-left {
  background: transparent !important;
}

.swiper-slide.swiper-slide-visible.swiper-slide-active .card {
  background: rgba(247, 234, 252, 1) !important;
}

.swiper-3d,
.swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1170px !important;
  /* z-index: -1 !important; */
}

.modal {
  border-radius: 10px !important;
  border-color: #fff !important;
}

.modal-content {
  border-color: #fff !important;
  border: 0 !important;
}

.modal-header .btn-close {
  width: 0% !important;
}

.modal-header {
  padding: 10px 25px 0px 25px !important;
  border-bottom: 0 !important;
}

.modal-body {
  padding: 10px 25px 0px 25px !important;
  border-bottom: 0 !important;
}

.modal-footer {
  border-top: 0 !important;
}

/* .modal-dialog {
  width: 100% !important;
  max-width: 32% !important;
} */

.mainAnchor {
  color: rgba(0, 123, 255, 1) !important;
  text-decoration: none !important;
}

.validation-msg {
  color: #ff8080 !important;
  font-size: 12px !important;
}

.modal.show .modal-dialog {
  transform: none !important;
  width: 100% !important;
  /* max-width: 50% !important; */
}

.validation-err {
  display: none;
}

.displaynone {
  display: none;
}

.validation-err-message {
  color: red !important;
  font-size: 11px !important;
  font-weight: 500 !important;
}

.theme-purple:before,
.theme-purple:after {
  background: none !important;
}

.theme-purple #wrapper:before,
.theme-purple #wrapper:after {
  background: none !important;
}

.left-inner-addon {
  position: relative;
}

.left-inner-addon input {
  /* padding-left: 43px !important;  */
}

.left-inner-addon .successBorder {
  border: 1px solid #01a16f !important;
  border-radius: 20px !important;
  padding-left: 43px !important;
}

.left-inner-addon .main {
  position: absolute;
  padding: 18px 18px !important;
  pointer-events: none;
  z-index: 3;
}

.left-inner-addon .mainone {
  position: absolute;
  padding: 16px 16px !important;
  pointer-events: none;
  z-index: 3;
}

a {
  color: var(--bs-link-color) !important;
}

.mainLI.active {
  width: auto !important;
}

.sidebar-nav .metismenu>li a {
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.navbar-nav .icon-menu {
  text-decoration: none !important;
}

.form-control.is-invalid {
  border: 2px solid red !important;
  /* color: red !important;  */
  /* /* background: red !important; */
}

.form-control.is-invalid1 {
  /* border: 2px solid red !important; */
  /* color: red !important;  */
  /* /* background: red !important; */
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  width: 100% !important;
}

.small .is-smallinvalid {
  border: 2px solid red !important;
  color: red !important;
}

.text-is-valid {
  display: none;
}

/* button:disabled {
  background: #dddddd !important;
} */

.Model_info {
  padding-top: 200px !important;
}

.successTextModel {
  color: #01a16f !important;
}

.successText {
  color: #28a745 !important;
}

.radiumTextModel {
  color: #c9d845 !important;
}

.cookieMain {
  position: fixed;
  left: 0;
  right: 3%;
  top: 60%;
  z-index: 99999;
}

.editbtn {
  size: 30px !important;
}

.mainDate {
  position: relative;
  border: 1px solid #dddddd !important;
  border-radius: 20px !important;
}

.example-custom-input {
  background: url("./assets/images/calendar-solid.svg") no-repeat;
  background-size: 20px;
  border: 1px solid #dddddd !important;
  background-position: right 20px center;
  border-radius: 20px !important;
}

.example-custom-input:focus-visible {
  border-bottom-color: #b51148;
  outline: none;
}

.statusDropdown {
  height: 100vh;
  max-height: 20vh;
  overflow-y: scroll;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #b51148;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  border: 1px solid #b51148;
  margin: 0.066rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #b51148;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.example-datepicker-class .react-datepicker__today-button {
  color: #b51148;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #aa2ae1 !important;
  font-weight: bold !important;
}

.nav-tabs .nav-link {
  color: #000 !important;
}

/* .nav-tabs .nav-tabs .nav-item.show .nav-link {
  color: #000  !important
} */

.bNav {
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.inputAppend {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.appenDropdown {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

#input-group-dropdown11 {
  width: 100% !important;
  max-width: 20% !important;
  border: 1px solid #dee2e6 !important;
  background: transparent !important;
  color: #000 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;

  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.sidebar-nav .metismenu>li.active>a {
  border-left-color: blueviolet !important;
  color: #aa2ae1 !important;
}

.sidebar-nav .metismenu a:hover {
  border-left-color: blueviolet !important;
  background: #fff !important;
  color: #aa2ae1 !important;
}

.main-radio {
  width: 22px !important;
  height: 22px !important;
}

.radio {
  margin-top: 7px !important;
}

.sidebar-nav .metismenu ul a::before {
  content: "" !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #fff !important;
}

#formGridEmail1 {
  /* color: #7F7F7F !important; */
  padding-left: 25px !important;
}

.OptionalValue {
  color: #7F7F7F !important;

}

.form-select:focus {
  box-shadow: none !important;
}

#KYCverifyButtonText {
  color: white !important;
}

#KYCverifyButton {
  width: 200px;
}

.block-header h2 {
  color: #aa2ae1 !important;
  font-weight: 500 !important;
}

.list-group {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #eeeaf5;
  border-radius: 0.5em;
  width: 20em;
}

.list-group li {
  border-top: 1px solid #eeeaf5;
  padding: 0.5em;
  display: flex;
  align-items: center;
}

.list-group li:first-child {
  border-top: 0;
}

.list-group .badge {
  background-color: rgb(71, 73, 84);
  color: #fff;
  font-weight: bold;
  font-size: 80%;
  border-radius: 10em;
  min-width: 1.5em;
  padding: 0.25em;
  text-align: center;
}

.jodit-status-bar {
  display: none !important;
}

.payment-select {
  width: 1250px;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}

.drpMenu {
  background: #F7EAFC !important;
}

.customCardOne {
  background: #f7eafc;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #f7eafc !important;
}

.form-select {
  color: gray !important;
  font-size: 15px !important;
}

.purpleText1 {
  color: #aa2ae1 !important;
  text-decoration: none !important;
}

.step4Editimg {
  max-width: 100%;
  height: 100%;
  cursor: pointer;
}

.chartjs-render-monitor {
  background: #f7eafc;
  border-radius: 20px;
  padding: 10px;
}

/* Floating labels */

.form-label1 {
  position: absolute;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 500 !important;
  line-height: inherit;
  left: 3.5rem;
  top: 16px;
  padding: 0 0.25rem;
  margin-bottom: 0 !important;
  color: #80878e !important;
  background: var(--color-white);
  transition: all 0.3s ease;
}

.form-input {
  /* position: absolute; */
  font-family: inherit;
  font-size: 1rem;
  line-height: inherit;
  top: 0;
  left: 0;
  z-index: 1;
  resize: none;
  width: 100%;
  /* height: auto; */
  /* padding: 0.75rem 1.25rem; */
  border-radius: 0.25rem;
  border: 1.5px solid var(--color-grays);
  color: var(--color-black);
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.form-input::-moz-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.form-input:-ms-input-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.form-input::placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.form-input:focus {
  outline: none;
  border: 2px solid var(--color-blues);
}

.form-input:focus~.form-label1 {
  top: -0.595rem;
  left: 3.5rem;
  z-index: 5;
  font-size: 0.875rem;
  font-weight: 500;
  color: #80878e;
  transition: all 0.2s ease-in-out;
}

.form-input:not(:-moz-placeholder-shown).form-input:not(:focus)~.form-label1 {
  top: -0.595rem;
  left: 3.5rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.form-input:not(:-ms-input-placeholder).form-input:not(:focus)~.form-label1 {
  top: -0.595rem;
  left: 3.5rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.form-input:not(:placeholder-shown).form-input:not(:focus)~.form-label1 {
  top: -0.595rem;
  left: 3.5rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}



#main-content {
  /* margin-top: 105px !important; */
}

.btn-light {
  background: #aa2ae1 !important;
  color: white !important;
  border-radius: 20px !important;
}

.caretCustom {
  z-index: 0;
  right: 0;
}

.mainSelect {
  z-index: 99;
}

.innerBoxCard {
  padding: 0px 31px;
}

.css-1jqq78o-placeholder {
  padding: 10px 0px;

}

.css-1dimb5e-singleValue {
  padding: 10px 0px;

}

.dragrableSmall {
  font-size: 18px !important;
}



.SelectValueSelect #react-select-2-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  padding: 10px 0px;

}

.SelectValueSelect .css-13cymwt-control {
  border: 1px solid #AA2AE1 !important;
  border-radius: 20px !important;
}

.SelectValueSelect99 .css-13cymwt-control {
  border: 1px solid #0484C5 !important;
  border-radius: 20px !important;
}

.SelectValueSelect99 .css-t3ipsp-control {
  border: 1px solid #0484C5 !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  padding: 0px 0px 0px 10px !important;
}

.SelectValueSelect .css-t3ipsp-control {
  border: 1px solid #AA2AE1 !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  padding: 0px 0px 0px 10px !important;
}

.SelectValueSelect .css-t3ipsp-control:hover {
  border: 1px solid #AA2AE1 !important;
  border-radius: 20px !important;
}

.SelectValueSelect .css-qbdosj-Input {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.SelectValueSelect .css-166bipr-Input {
  visibility: visible;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
}

.SelectValueSelect .css-1u9des2-indicatorSeparator {
  background-color: #d6d6d6 !important;
}

.SelectValueSelect .css-tj5bde-Svg {
  color: #AA2AE1 !important;
}

.SelectHoverLabel {
  font-family: inherit;
  font-size: 1rem;
  line-height: inherit;
  /* z-index: 10; */
  resize: none;
  border-radius: 0.25rem;
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.SelectHoverLabel::-moz-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.SelectHoverLabel:-ms-input-placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.SelectHoverLabel::placeholder {
  opacity: 0;
  visibility: hidden;
  color: transparent;
}

.SelectHoverLabel:focus {
  outline: none;
  border: 2px solid var(--color-blues);
}

.SelectHoverLabel:focus~.form-label1 {
  top: -0.595rem;
  left: 3.50rem;
  z-index: 5;
  font-size: 0.875rem;
  font-weight: 500;
  color: #80878e;
  transition: all 0.2s ease-in-out;
}

.SelectHoverLabel:not(:-moz-placeholder-shown).SelectHoverLabel:not(:focus)~.form-label1 {
  top: -0.595rem;
  left: 3.50rem;
  z-index: 9;
  font-size: 0.875rem;
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.SelectHoverLabel:not(:-ms-input-placeholder).SelectHoverLabel:not(:focus)~.form-label1 {
  top: -0.595rem;
  left: 3.50rem;
  z-index: 11;
  font-size: 0.875rem;
  font-weight: 500;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.SelectHoverLabel:not(:placeholder-shown).SelectHoverLabel:not(:focus)~.form-label1 {
  top: -0.595rem;
  left: 3.50rem;
  z-index: 11;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.left-inner-addon11 {
  position: relative;
}

.left-inner-addon11 input {
  /* padding-left: 43px !important;  */
}

.left-inner-addon11 .successBorder {
  border: 1px solid #01A16F !important;
  border-radius: 20px !important;
  padding-left: 43px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.left-inner-addon11 .main {
  position: absolute;
  padding: 18px 18px;
  pointer-events: none;
}

.methodName {
  width: 1100px !important
}

.methodName1 {
  /* width: 1330px !important */
}

.paymentImage {
  width: 150px !important;
  border: 1px solid black;
}

.deliverImage {
  width: 110px !important;
  border: 1px solid black;
}

.labelText {
  font-size: 14px;
}

.SelectValueSelect1 .css-13cymwt-control {
  border: 1px solid #AA2AE1 !important;
  border-radius: 20px !important;
}

.SelectValueSelect1 .css-t3ipsp-control {
  border: 1px solid #AA2AE1 !important;
  border-radius: 20px !important;
}

.SelectValueSelect1 .css-t3ipsp-control:hover {
  border: 1px solid #AA2AE1 !important;
  border-radius: 20px !important;
}

.SelectValueSelect3 .css-13cymwt-control {
  border: 1px solid #F3852D !important;
  border-radius: 20px !important;
}

.SelectValueSelect3 .css-t3ipsp-control {
  border: 1px solid #F3852D !important;
  border-radius: 20px !important;
}

.SelectValueSelect3 .css-t3ipsp-control:hover {
  border: 1px solid #F3852D !important;
  border-radius: 20px !important;
}

.nationality .css-1fdsijx-ValueContainer {
  padding-left: 32px !important;
}

.checkboxError {
  display: flex;
}

.deleteButton {
  width: 100px;
}

.CreateServiceChargeDynamicCard {
  border: 1px solid rgb(190, 187, 187);
  background-color: rgb(250 250 250);
  box-shadow: 1px 2px black;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  /* background: #fff !important; */
  color: #17191c !important;
  font-weight: 400 !important;
}


.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus .nav-tabs .nav-link:focus-within {
  /* border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 0px solid transparent !important; */
  /* transition: unset !important; */
  /* border-color: transparent !important; */
  isolation: unset !important;
  /* border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
}

.error-text-color {
  color: red !important;
}

.left-inner-addon .main {
  left: 0px !important;
}

.is-invalidError {
  border: 2px solid red !important;
}

.ServicechargeBorder {
  border-top: 2px solid #000000 !important;
}

.counterDay {
  display: flex;
  justify-content: space-between;
}

.clients-main {
  margin-top: 5%;
}

.how {
  margin-top: 6% !important;
}

.textEnd {
  text-align: end;
}

.textStart {
  text-align: left;
}

.footerMargin {
  border-bottom: 1px solid #dee2e6;
}

.innerFooterDflex {
  display: flex;
  justify-content: space-between;
}

.paddingFooter {
  padding-bottom: 40px;
}

.touchBTn {

  margin-top: 10% !important;
  text-transform: uppercase;
  font-weight: bold !important;
  border-radius: 0 !important;
  background: #175FA5 !important;
}

.mm {
  padding: 13px;
}

.stepsSign {
  padding-top: 30px;
  padding-bottom: 15px;
}

.moneySendRespo {
  display: flex !important;
}

.innerIconRec {
  padding: 20px;
  display: flex;
  margin-left: 2px !important;
}

.indAcc {
  margin-left: 11px;
  font-size: 15px !important;
}

.left-inner-addon input {
  /* padding-top: 24px !important; */
  /* padding-bottom: 15px !important; */
}

.copyText {
  position: relative;
}

.imageCopy {
  position: absolute;
  top: 20%;
  right: 5%;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  /* z-index: 200 ; */
}

.dropdown-container {
  position: relative;
  z-index: 1;
}

.innerAbtPage {
  padding: 30px;
}

.purpleText1 {
  color: #aa2ae1 !important;
  /* cursor: pointer; */
}

.kk {
  margin-left: 14px;
}

.tableContainer {
  overflow-x: auto;
}

.responsiveFontSmall {
  font-size: 10px;
  color: red;
}

/* .svg-inline--fa{
  color:#aa2ae1 !important
} */

#left-sidebar .nav-tabs .nav-link.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.liInnerSettings {
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #17191c !important;
  padding: 15px 5px;
}

.dropdown-toggle::after {
  /* color: #AA2AE1; */
}

thead,
th {
  background-color: #F4F0FF !important;
  padding: 15px 15px !important;
  border: 0 !important;
  box-shadow: none !important;
}

tbody,
tr td {
  padding: 15px 15px !important;
  box-shadow: none !important;


}

.css-12j7rf6-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: #F7EAFC !important;
  color: #000 !important;
  border-radius: 63px;
}

.css-12j7rf6-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: transparent;
}

.css-12j7rf6-MuiButtonBase-root-MuiPaginationItem-root {
  min-width: 30px !important;
  height: 50px !important;
}

.nav-tabs-new>li>a.active {
  border: 1px solid #F7EAFC !important;
  background: #F7EAFC !important;
  color: #000 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
}

.mainPill2 {
  color: #000 !important;
}

.mainPill {
  color: #000 !important;
  box-shadow: 0 3px 0px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px !important;
}

.table .thead-light th {
  font-weight: 500 !important;
}

.css-j204z7-MuiFormControlLabel-root {
  margin: 0;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 6px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.touch {
  background: #175fa5 !important;
}

input[type=checkbox],
input[type=radio] {
  border-color: #17191c !important;
}

.filterBg {
  background: #f0fbfe !important;
}

.labelCard {
  color: #012b72 !important;
  font-weight: 700 !important;
}

.lightPurple {
  color: #F7EAFC !important;
}

.css-1p5nen7-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}

.jodit-add-new-line,
.jodit-add-new-line * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: none !important;
}

.mainPillActive {
  background-color: transparent !important;
  border: 1px solid #AA2AE1 !important;
  color: #000 !important;
}

.activeTab11 {
  background-color: #aa2ae1;
}

/* .btn:hover{
  background-color: transparent !important;
} */

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0px 0px 0px -3px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 4px 0px rgba(0, 0, 0, 0.12) !important;
}

.paginationEnd {
  justify-content: end;
}

.paginationBetween {
  justify-content: space-between;
}

select.form-control:not([size]):not([multiple]) {
  z-index: 0;
}

.CreateServiceChargeDynamicCard {
  padding: 20px 20px 20px 20px;
}

.INDtabs {
  align-items: baseline;
  justify-content: space-between;
}

.liInnerSettings.active {
  font-weight: normal !important;
  border-left: 5px solid #aa2ae1 !important;
  /* border-left-color: blueviolet !important; */
  background-color: #f1f1f1 !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  /* border-radius: 10px !important; */
  background-color: #F5F5F5 !important;
}

::-webkit-scrollbar {
  width: 10px !important;
  background-color: #F5F5F5 !important;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #AA2AE1 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;
  background-color: #AA2AE1 !important;
}

.purpleLIghtShade {
  background: #FCF7FE;
}


.error-page-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  background-size: cover;
  position: relative;
  padding: 0 20px;
}

.error-page-container:after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}

.error-page-container * {
  position: relative;
  z-index: 1;
}

.error-page-container img {
  width: 100%;
  max-width: 500px;
  margin-bottom: 25px;
}

.error-page-container h4 {
  color: #333;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.error-page-container .button {
  display: inline-block;
  margin-top: 20px;
  padding: 18px 30px;
  background: white;
  color: #6c63ff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 4px;
}

.error-page-container .button:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}



.empty-state {
  width: 750px;
  margin: 40px auto;
  background: #ffffff;
  /* box-shadow: 1px 2px 10px #e1e3ec; */
  border-radius: 4px;
}

.empty-state__content {
  padding: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.empty-state__content .empty-state__icon {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  /* box-shadow: 0px 2px 1px #e1e3ec; */
}

.empty-state__content .empty-state__icon img {
  width: 170px;
}

.empty-state__content .empty-state__message {
  /* color: #38a169; */
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
}

.empty-state__content .empty-state__help {
  color: #a2a5b9;
  font-size: 0.875rem;
}

.credit {
  color: #A2A5B9;
  font-size: 0.75rem;
  text-align: center;
}

.credit a {
  color: #444;
}

.accordion-button::after {
  filter: invert(1);
}

.ssss {
  width: 100% !important;
  max-width: 60% !important;
}

.clock-icon {
  cursor: pointer;
  margin-left: 5px;
}

.tooltip-inner {
  background-color: #000;
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}

.clock-icon {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}

.item-stock {
  font-size: 12px !important;
}

.days-ago-tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}


/* .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root{
  z-index: 0 !important;
  left: -100px !important;
} */


.chatMainSection11 {
  height: 100vh;
  max-height: 40vh;
  overflow-y: hidden;
}

.chatMainSection22 {
  height: 100vh;
  max-height: 60vh;
  overflow-y: auto;
}

.breakAll {
  word-break: break-all;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent !important;
  border-color: transparent !important;
}

th {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

th[title]:hover:after {
  content: attr(title) !important;
  position: absolute !important;
  top: 100% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  white-space: nowrap !important;
  padding: 4px !important;
  background-color: #333 !important;
  color: #fff !important;
  border-radius: 4px !important;
  z-index: 999 !important;
}


.no-scroll {
  overflow-y: hidden !important;
}

.rdw-dropdown-optionwrapper {
  overflow: hidden !important;
}

.rdw-colorpicker-modal {
  width: 189px !important;
  height: 190px !important;
}

.rdw-colorpicker-modal-options {
  overflow: hidden !important;
}

.rdw-editor-toolbar {
  font-size: 13px !important;
}

.parent-container .css-13cymwt-control {
  background-color: transparent;
}

.custom__error .css-13cymwt-control {
  border: 2px solid red !important;
  border-radius: 20px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.newsCrds {}

body.menu-open {
  overflow-y: scroll !important;
  position: fixed !important;
  width: 100% !important;
}

.carousel-card {
  height: auto;
  /* Reset the height to auto before applying the calculated height */
  transition: height 0.3s;
  /* Add a transition for smooth height changes */
}

.navMedia {
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
}

.dropdown-menu11 {
  background: transparent !important;
}

.custom-dropdown-toggle .dropdown-toggle::after {
  display: none !important;
}

/*.slick-arrow {*/
/*  display: none !important;*/
/*}*/

.owl-dots {
  display: none !important;
}

.owl-nav {
  display: flex;
  justify-content: center !important;
}

/* PersonalInformation.css */

/* PersonalInformation.css */

/* PersonalInformation.css */

/* PersonalInformation.css */

.custom-bullet-list {
  list-style-type: none;
  padding-left: 0;
}

.custom-bullet-list li {
  line-height: 0.9;
}

.custom-bullet-list li:before {
  content: "•";
  line-height: 0.9;
  margin-right: 8px;
  /* Adjust the margin to control spacing between bullet and text */
  font-size: 2em;
  /* Adjust the font size to make the bullet bigger */
}


.btnINDIUser {
  justify-content: end;
  margin-left: auto;
}

.flexRowReverse {
  flex-direction: row-reverse;
}

.textEnd {
  text-align: end !important;
}

.marginLeftUtility {
  margin-left: 13px;
}

.marginLeftUtility1 {
  margin-left: 30px;
}

.excLeft {
  margin-left: 65px;
}

.wallLeft {
  margin-left: 65px;

}

.promoPLeft {
  padding: 0px 24px 0px 24px;
}

.sendMoneyPaddingForm {
  padding: 0px 24px 0px 24px;
}

.leftLogo {
  margin-left: 12px;
}

.invisiblesendBtn {
  margin-left: 16px;
}

.filterAccountsMain {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.filterAccountsInner {
  display: flex;
  align-items: baseline;
}

.addnewFilter {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.ReportFraudModal {
  padding: 20px;
}

.dflexSwitch {
  display: flex;
  margin-left: auto;
}

.otpModal {
  margin: 7px;
}

.viewModal {
  height: 100vh;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.rdrMonthAndYearWrapper button {
  background: transparent !important;
}

.rdrDateDisplayWrapper {
  background-color: transparent !important;
}

.rdrCalendarWrapper button {
  width: 44px !important;
  height: 40px !important;
}

.rdrMonthAndYearPickers select {
  border: 0 !important;
}

/* Works on Chrome, Edge, and Safari */
textarea::-webkit-scrollbar {
  width: 12px;
}

textarea::-webkit-scrollbar-track {
  background: #F5F5F5;
  border-radius: 12px 90px 12px 90px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #AA2AE1;
  border-radius: 20px;
  border: 3px solid #FCF7FE;
}

.positionEyeBtn {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  bottom: 0;
  top: 0;
  padding-right: 10px;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.liInnerSettings::marker{
  content: '';
}
ul,.collapse11::marker{
  content: '';
  list-style-type: none !important;
}
.WhyChooseUsAlignment{
  flex-direction: column;
  align-items: end;
  text-align: end;
}

.FirstFlexReverse{
  align-items: center;
}

.offcanvas.offcanvas-start{
  width: 350px !important;
}

.mainChatApp1{
  width: 100% ;
  max-width: 49.5%;
  margin-left: 4px ;
}
/* media queries */

@media (max-width: 1300px) and (orientation: landscape) {
  .moneyCard {
    top: 12%;
  }

  #tsparticles {
    height: 200vh !important;
    max-height: 115vh !important;
  }

  .cookieBox {
    background: #f7eafc !important;
    border-radius: 10px;
    width: 100% !important;
    max-width: 39% !important;
  }

  .cookieMain {
    position: fixed;
    left: 0;
    right: 3%;
    top: 48%;
    z-index: 99999;
  }
}

@media (max-width: 1400px) {
  .rewardRespFont {}

  .mainInternational {
    background-position: right;
  }

  .SOADRp {
    margin-right: 150px;
  }

  .navMedia {
    padding-left: 14px !important;
  }

  .box .steps ul li .step1:after {
    width: calc(100% - 21px) !important;
  }

  .navbtn {
    font-size: 13px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .rewardRespFont {}

  .SOADRp {
    margin-right: 150px;
  }

  .box .steps ul li .step1:after {
    width: calc(100% - 21px) !important;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .rewardRespFont {
    font-size: 12px;
  }

  .SOADRp {
    margin-right: 160px !important;
  }

  .navMedia {
    padding-left: 14px !important;
  }

  .box .steps ul li .step1:after {
    width: calc(100% - 26px) !important;
  }

  .dropdown-btn {
    max-width: 50vh !important;
  }

  .dropdown-items {
    max-width: 50vh !important;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .project_reportMobile {
    padding: 20px 0px 20px 0px !important;
  }

  .paddLefRightZero {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .form-label1 {
    font-size: 0.6rem !important;
  }

  .otpModal {
    margin: 0px;
  }

  .otpModal input {
    height: 100px !important;
    max-height: 48px !important;
    margin: 5px 5px 5px 5px !important;
  }

  .WFull{
    width: 100% !important;
  }


  .box .stepsmain ul li .step3:after,
  .box .stepsmain ul li div:after {
    padding: 0px 9px !important;
  }

  .dflexSwitch {
    margin: auto;
  }

  .ReportFraudModal {
    padding: 15px;
  }

  .respoAddNew {
    margin-top: 20px;
  }

  .addnewFilter {
    margin: auto;
  }

  .filterAccountsMain {
    flex-direction: column;
  }

  .filterAccountsInner {
    flex-direction: column;
    align-items: center;
  }

  .navPillResp {
    justify-content: center;
  }

  .invisiblesendBtn {
    margin-left: 29px;
  }

  .viewModal {
    height: 100vh;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .leftLogo {
    margin-left: 0px !important;
  }

  .navbar-nav .dropdown-menu {
    overflow-x: hidden !important;
  }

  .sendMoneyPaddingForm {
    padding: 0px 12px 0px 12px;
  }

  .moneyBoxLabel {
    width: 115px;
  }

  .input-group {
    flex-wrap: inherit !important;
  }

  .promoPLeft {
    padding: 0px;
  }

  .excLeft {
    margin-left: 0px;

  }

  .wallLeft {
    margin-left: 20px;

  }


  .modal-money-payment {

    overflow-y: scroll !important;
    overflow-x: hidden;
    padding: 0px 8px 0px 10px !important;
  }

  .dropdown-container {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .quatation {
    width: 64px !important;
  }

  .marginLeftUtility {
    margin-left: 0px;
  }

  .marginLeftUtility1 {
    margin-left: 0px;
  }


  .flexColReverse {
    flex-direction: column-reverse;
  }

  #customer-testimonoals .item {
    padding: 25px !important;
  }

  .ddList {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .noPadding {
    padding: 0 !important;
  }

  .btnINDIUser {
    margin: auto;
  }

  .tab-content {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .mainUl.nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    padding-left: 0 !important;
    margin: 0 !important;
  }

  .mainUl.nav-tabs .nav-link,
  .nav-tabs .nav-item.show .nav-link {
    margin: 0 !important;

  }

  .mobilemainUL {
    display: block !important;
    margin: 0 !important;

  }

  .legalText {
    margin-top: 13px;
  }

  .innerAbtPage {
    padding: 5px;
  }

  .paginationEnd {
    justify-content: center;
  }

  .paginationBetween {
    justify-content: center;
    align-items: center;
  }

  .dropdown-items {

    padding: 14px 33px !important;
    margin-left: 0px !important;
  }

  .innerIconRec {
    padding: 5px;
  }

  /* Signup page resposnive media queries */

  .box .steps ul li.firstStepactive div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.secondStepactive div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.thirdStepactive div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.fourthStepactive div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.fifthStepactive div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.sixthStepactive div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.finalStepactive div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.firstStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.secondStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.thirdStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.fifthStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.fourthStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.fifthStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.sixthStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .box .steps ul li.finalStepremain div {
    width: 30px !important;
    height: 30px !important;
  }

  .stepLabel {
    display: none;
  }

  .box .steps ul li .step1:after,
  .box .steps ul li div:after {
    top: 14px !important;
    left: -112% !important;
    height: 2px !important;
  }

  .box .steps ul li .step3:after,
  .box .steps ul li div:after {
    top: 14px !important;
    left: -97% !important;
    height: 2px !important;
  }

  /* .box .steps ul li .step1:after,
  .box .steps ul li div:after {
    top: 14px !important;
    left: -100% !important;
    height: 2px !important;
  } */

  .box .steps ul li .step1:after {
    width: calc(100% - 21px) !important;
  }

  .box .steps ul li .step3:after {
    width: calc(100% - 26px) !important;
  }

  .firstBlock {
    display: none;
  }



  .secondBlock {
    padding: 0 !important;
  }

  .stepsSign {
    padding-bottom: 30px;
  }

  .bgPurple {
    max-width: 70% !important;
  }

  .bgOrange {
    max-width: 70% !important;
  }


  .bgGreen {
    max-width: 70% !important;
  }

  .step4Welcome {
    max-width: 70% !important;
  }

  .step5Welcome {
    max-width: 70% !important;
  }


  .step6Welcome {
    max-width: 70% !important;
  }

  .step7Welcome {
    max-width: 70% !important;
  }

  .bgSkyBlue {
    max-width: 70% !important;

  }

  .loginPadding {
    padding-left: 12px !important;
  }

  .login {

    padding-bottom: 40px;

  }

  /* .modal.show .modal-dialog{
      max-width: 100% !important;
    } */
  /*  */

  .aboutMain {
    margin-top: 5% !important;
  }

  .mbStep {
    margin-bottom: 3%;
  }

  #dropzone .drp11 {
    right: 15px;

  }

  .textEnd {
    text-align: justify;
  }

  .textStart {
    text-align: justify;
  }

  .counterDay {
    display: flex;
    /* flex-direction: column; */
  }

  .maTopRes {
    margin-top: 10% !important;
  }

  .first1d {
    margin-bottom: 5%;
  }

  .currencySection {
    padding-bottom: 2% !important;
  }

  .customerRes {
    line-height: 22px !important;
  }

  .fromDivSelect {
    margin-top: 10%;
  }

  .responsiveMainForMobile {
    padding: 8px;
  }

  .sendMoneyPadding {
    padding: 0px 10px 0px 10px;
  }

  .responsiveFontLarge {
    font-size: 0.8rem !important;
  }

  .utilityMain {
    padding-top: 0px !important;
  }

  .playStoreBtn {
    display: flex !important;
    justify-content: center !important;
  }

  .paddingZero {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .responsiveFontLargeHeading {
    font-size: 1.6rem !important;
  }

  .responsiveFontLargeMedium {
    font-size: 1.2rem !important;
  }

  .innerBoxCard {
    padding: 0px;
  }

  .respo-navbar {
    padding: 20px 30px !important;
  }

  #responsive-navbar-nav {
    z-index: 999;
    background: #fff;
    border-radius: 16px;
    padding: 17px 5px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    margin-top: 20px;
    width: 100vh;
    max-width: 30vh;
    margin-left: auto;
  }

  .rspoH1 {
    font-size: 24px !important;
    line-height: 35px !important;
    padding-top: 18px !important;
    text-align: center;
  }

  .rspoP {
    font-size: 16px !important;
    text-align: center;
    line-height: 20px !important;
  }

  .respoInter {
    padding-top: 40%;
  }

  .navbar-toggler-icon {
    color: #fff;
    filter: invert(1);
  }

  .WhyChooseUsAlignment {
     align-items: baseline !important;
     text-align: start !important;
    }

  .respoChildFooter {
    flex-direction: column !important;
  }

  .innerFooterChildItem {
    justify-content: center;
  }

  .childTextInner {
    text-align: center;
  }

  .respoMoneyCard {
    flex-direction: column;
  }

  .moneySendRespo {
    display: none !important;
  }

  .chartRespo {
    flex-direction: column;
  }

  .swingVector1 {
    display: none;
  }

  .mainInternational {
    padding-top: 20% !important;
  }

  .respoInter {
    flex-direction: column;
  }

  .client-img-main {
    max-width: 100% !important;
  }

  .partner-main {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  #customer-testimonoals .item {
    padding: 2px 30px 2px 30px;
  }

  body {
    overflow-x: hidden !important;
  }

  .shadow-effect {
    padding: 18px 0px 18px 0px;
  }

  /* .footer-main{
    padding-top: 40px ;
  } */

  .footerMargin {
    margin-top: 40px !important;
    border: 0;
  }

  .innerFooterDflex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .paddingFooter {
    padding-bottom: 30px;
  }

  .touchBTn {
    margin-bottom: 10% !important;
  }

  .mm {
    padding: 13px;
  }

  .mainMoneyBox {
    margin: 0px 0px 0px 0px !important;
  }

  .box .stepsmain ul li.firstStepactive div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.newStepremain div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.secondStepactive div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.newStepactive div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.thirdStepactive div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.fourthStepactive div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.secondStepremain div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.thirdStepremain div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li.fourthStepremain div {
    width: 40px !important;
    height: 40px !important;
  }

  .box .stepsmain ul li .step3:after,
  .box .stepsmain ul li div:after {
    top: 19px !important;
    left: 28% !important;
  }

  .box .stepsmain ul li .step3:after {
    width: calc(100% - 16px) !important;
  }

  .form-control {
    padding: 22px 17px 23px 13px !important;
  }

  .verifyButton1 {
    margin-right: 10px !important;
  }

  .stepper-wrapper {
    flex-direction: column;
  }

  .respTextCenter {
    text-align: center;
  }

  .cashPadding {
    padding: 18px 23px 17px 24px;

  }

  .referTop {
    margin-top: 16px !important;
  }

  .coupon-card {
    padding: 20px 50px !important;
  }

  .overflowYscrollResp {
    overflow-x: auto !important;
  }

  .pbSt {
    margin-bottom: 20px !important;
  }

  .pbDowSt {
    margin-top: 20px !important;
  }

  .rhombus {
    background-color: transparent !important;
    filter: brightness(0.5) !important;
  }

  .contactD {
    width: 45vh;
  }

  .pbBox {
    background: none !important;
    height: auto !important;
    max-height: 0 !important;
  }

  .mainFontSize {
    font-size: 12px !important;
  }

  .mainFontSize2 {
    font-size: 13px !important;
  }

  .step-name {
    font-size: 13px;
    font-weight: 500;
  }

  div.p p {
    padding: 0em !important;
  }

  .stepper-item .step-counter {
    width: 30px !important;
    height: 30px !important;
  }

  .stepper-item.completed::after {
    display: none;
  }

  .stepper-item::before {
    display: none;
  }

  .stepper-item::after {
    display: none;
  }

  .mileTop {
    margin-top: 50px;
  }

  .navbar-fixed-top .navbar-right {
    width: 150px !important;
  }

  .css-12j7rf6-MuiButtonBase-root-MuiPaginationItem-root {
    min-width: 40px !important;
    height: 40px !important;
    margin: 0 !important;
  }

  .INDtabs {
    flex-direction: column;
    align-items: initial;
    justify-content: space-between;
  }

  .leavesMain {
    flex-direction: column;
  }

  .subFooter {
    position: inherit !important;
  }

  .footer-main {
    padding-top: 50px !important;
  }

  .KYCFont{
    font-size: 12px;
  }
  .FirstFlexReverse{
    flex-direction: column-reverse;
    align-items: baseline;
  }
  .svg-icon--search {
    max-width: 6% !important;
}
.chatSectionList{
  display: none;
}
.fileINP{
  top: 13% !important;
}

.mainChatApp {
  width: 100%;
  max-width: 97.4% !important;
  margin-left: 4px !important;
}
.mainChatApp1 {
  width: 100%;
  max-width: 97.4% !important;
  margin-left: 4px !important;
}
}
