.clients-main {
  background: #fcf7fe;
  
}


.cardTestinomial{

    background: #fff !important;
    border-radius: 20px !important;
    border: 0 !important; 
    // box-shadow: 0px 4px 13px 3px rgba(0, 0, 0, 0.06) !important;
}

.usr{
    width: 100% !important;
    max-width: 15% !important;
}
.quatation{
    position: absolute;
    width: 100px !important;
    right: 5%;
}

.card{
    border: 0 !important;
    border-radius: 20px !important;
    border: 0 !important; 
}
.card-body {
    
}
.testinomialCard {
    box-shadow: 0px 4px 13px 3px rgba(0, 0, 0, 0.06) !important;
}

/* .owl-nav{
    display: flex !important;
    justify-content: center;
} */
.owl-prev{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    background: #F7EAFC !important;
}
.owl-next{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    background: #F7EAFC !important;
}

@media only screen and (min-width: 600px){

    .reviewblock{
        background: white;
        padding:40px 100px !important;
    }
    
  }
    
  .personal-image {
    position: relative;
    text-align: center;
  }
  .personal-image input[type="file"] {
    display: none;
  }
  .personal-figure {
    position: relative;
  }
  .personal-avatar {
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 100%;
    border: 2px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out .3s;
  }
  .personal-avatar:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .personal-figcaption {
    cursor: pointer;
    position: absolute;
    top: 0px;
    width: inherit;
    height: inherit;
    border-radius: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease-in-out .3s;
  }
  .personal-figcaption:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, .5);
  }
  .personal-figcaption > img {
    margin-top: 32.5px;
    width: 50px;
    height: 50px;
  }
  
  .avatar-edit{
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    margin: auto;
    text-align: center;
    bottom: 0;
    right: 0;
    rotate: 90deg;
  }
  
  .starRate{
    font-size: 30px;
  }

  .star-ratings{
    display: flex !important;
    justify-content: center !important;
  }