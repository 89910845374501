.nav-pills-custom .nav-link{
    font-size: 18px !important;
}


.nav-pills-custom .nav-link.active {
    background: #AA2AE1 !important;
    color: #fff !important;
    border-radius: 20px !important; 

  }


