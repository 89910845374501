
.container1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
  
  .card-container {
    background-color: #fff;
    border-radius: 8px;
    margin: auto;
    box-shadow: 0 2px 20px rgba(0,0,0, 0.1);
    overflow: hidden;
  }
  
  .card-image img {
    border-radius: 8px 8px 0 0;
    background-size: cover;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 16px;
  }
  
  .card-badge {
    text-transform: uppercase;
    background-color: #fff;
    color: #fff;
    padding: 2px 8px;
    border-radius: 70px;
    margin: 0;
    font-size: 12px;
  }
  
  .card-badge-blue {
    background-color: #92d4e4;
  }
  
  .card-badge-purple {
    background-color: #3d1d94;
  }
  
  .card-badge-pink {
    background-color: #c62bcb;
  }
  
  .card-body h1 {
    font-size: 16px;
    margin: 8px 0;
  }
  
  .card-body p {
    font-size: 14px;
    margin: 8px 0 16px 0;
  }
  
  .card-author {
    display: flex;
    align-items: center;
  }
  
  .card-author p {
    margin: 0 16px;
    font-size: 12px;
  }
  
  .card-author p:last-child {
    color: #888;
  }
  
  .card-author img {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    margin-top: auto;
  }
  
  @media screen and (max-width: 1000px) {
    .container1 {
      grid-template-columns: 1fr;
    }
  }

  /* styles.css */
.custom-nav .nav-link {
    color: #333; /* Default color for nav links */
  }
  
  .custom-nav11 .nav-link.active {
    background-color: #c62bcb !important; /* Change background color of active tab */
    color: #fff !important;   /* Change text color of active tab */
    font-weight: 600;
    border-radius: 4px; /* Add rounded corners to active tab */
    border-color: #007bff; /* Add border color to active tab */
    border-width: 1px; /* Set border width for active tab */
  }
  

  .custom-nav-link{
    color: #c62bcb !important;
    font-weight: 600 !important;
  }