

.innerHeaderText{
  display: flex;
    background: #f7eafc ;
}

.mainAccord {
    border: 1px solid rgba(170, 42, 225, 0.5) !important;
border-radius: 20px !important;
background: #fff !important;
}


div.c{
    position: relative;
    border: 1px solid rgba(170, 42, 225, 0.5);
border-radius: 20px;
  }
  
  .checkMain{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity:0;
    visibility: 0;
  }

  .countinueTransactionlogo{
    width: 26px;
  }


  div.p{
    max-height:0px;
    overflow: hidden;
    transition:max-height 0.5s;
  }
  div.p p {
    padding:2em;
  }
  .checkMain:checked ~ h1 label::before{
    border-left:15px solid transparent;
    border-top:20px solid white;
    margin-top:12px;
    margin-right:10px;
  }
  .checkMain:checked ~ h1 ~ div.p{
    max-height:300px;
  }
  a{
    color:steelblue;
  }

  .innerP{
    font-size: 16px ;
  }

  

//   stepper css


.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 3px solid #D1D5DB;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  .stepActive{
    border: 2px solid #AA2AE1 !important;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    margin-bottom: 6px;
    border: 2px solid #D1D5DB;
  }
  
  .stepper-item.active {
    font-weight: bold;
  }
  
  .stepper-item.completed .step-counter {
    background-color: #AA2AE1;;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 3px solid #AA2AE1;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }

  // .delievered{
  //   background: rgba(221, 225, 42, 0.2);
  // }

  ////////////////////////////////////
  .Draft{
    background: #FAEDFF;
    
  }

  .Confirmed{
    background: #C4E9FF;
  }

  .Unconfirmed{
    background-color: #C4FFF1;
  }

  .Processing{
    background-color: #FFDDD7;
  }

  .compliancehold{
    background-color: #C4E9FF;//#EAEAEC; Confiremed colour it here CH colour comment
  }

  .Pending{
    background-color: #FFF9D9;
  }

  .Delivered{
    background-color: #FFFF;
  }

  .Cancelled{
    background: #FDFDF2;
  }
  
  .Refunded{
    background-color: #E5E5C3;
  }

  .custom-dropdown-toggle::after {
    display: none !important;
  }
  .borderShadeColor{
    color: #ccc;
  }