.browseButton {
    background: #0484C5 !important;
    border-color: #0484C5 !important;
    border-radius: 0px 22px 22px 0px !important;
    color:#fff !important;
    font-size: 15px !important;
    }

    .main-input{
        border-color: #0484C5 !important;
        border-radius: 20px 0px 0px 20px !important;
    }


    .images {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between  !important;
        align-items: center !important;
      }

      .image {
        margin: 1rem 0.5rem !important;
        position: relative !important;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px !important;
      }

      .image .mainBtnClose {
        position: absolute !important;
        bottom: 0 !important;
        top: 2% !important;
        right: 2% !important;
        cursor: pointer !important;
        border: none !important;
        color: white !important;
        background: transparent;
      }


      .image p {
        padding: 0 0.5rem !important;
        margin: 0 !important;
      }

      .upload-btn {
        cursor: pointer !important;
        display: block !important;
        margin: 0 auto !important;
        border: none !important;
        border-radius: 20px !important;
        width: 10rem !important;
        height: 3rem !important;
        color: white !important;
        background-color: green !important;
      }

      #dropzone {
        position: relative;
        background: #EDF0F4;
        border-radius: 25px;
        color: white;
        font: bold 24px/200px arial;
        height: 200px;
        text-align: center;
      }

      #dropzone.hover {
        border: 10px solid #FE5;
        color: #FE5;
      }

      #dropzone.dropped {
        background: #222;
        border: 10px solid #444;
      }

      #dropzone .drp11 {
         position: absolute;
      z-index:0;
        bottom: 30%;


      }


      #dropzone [type="file"] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      /* ------------------------------- */

      #dropzone1 {
        position: relative;
        background: #EDF0F4;
        border-radius: 25px;
        color: white;
        font: bold 24px/200px arial;
        height: 200px;
        text-align: center;
      }

      #dropzone1.hover {
        border: 10px solid #FE5;
        color: #FE5;
      }

      #dropzone1.dropped {
        background: #222;
        border: 10px solid #444;
      }

      #dropzone1 .drp11 {
         position: absolute;
      z-index:0;
        bottom: 30%;


      }


      #dropzone1 [type="file"] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      /* label:hover,
      .upload-btn:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
          rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
      } */

      .error {
        text-align: center !important;
      }

      .error span {
        color: red;
      }

      .uploaderDrop{
        width: 100% !important;
        max-width: 20%;
      }

      .uploadedImage{
        max-width: 100px !important;
        height: 100px !important;
        object-fit: cover;
        object-position: center;
      }

      .img-box {
        position: relative;
        max-width: 100px !important;
        height: 100px !important;
      }



      .img-box::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        max-width: 100px !important;
        height: 100px !important;
        background-color: red !important;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 200ms;
      }

      .img-box:hover::after {
        opacity: 1;
      }

      .cta .preview {
        width: 35px !important;
        height:35px !important;
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
        text-decoration: none;
        text-transform: uppercase;
        font-family: sans-serif;
        font-weight: bold;
        color: black;
        opacity: 0;
        transition-property: all;
        transition-duration: 500ms;
        z-index: 2;

      }

      .img-box:hover .cta .preview {
        display: none !important;
        opacity: 1;
        left: 50%;
      }

      .cta .preview:hover {
        transform: translate(-50%, -50%) scale(1.1);
      }

      .effect-image-1{
        position: relative;
        display: block;
      }

      .overlay{
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left:0;
        top:0;
        background: rgba(0, 0, 0, 1);
        opacity: 0.5 !important;
        visibility: hidden;
        cursor: pointer;
      }

      .simple-overlay{
            opacity: 0;
            visibility: hidden;
        }
        .effect-image-1:hover .simple-overlay{
        cursor: pointer;
            visibility: visible;
            opacity: 1;
        }
