.spantagCSV{
    font-size: 12px !important;
    text-decoration: none !important;
    color: black !important;
}
.spantagCSVSize{
    font-size: 15px !important;
    text-decoration: none !important;
    color: black !important;
}
.line {
    height: 1px !important;
    width: 100%;
    background-color: rgb(0, 0, 0) !important;
}