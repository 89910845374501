.mainToolBar{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.navLink{
    font-weight: 500 ;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 24px !important;
    /* identical to box height */
    color: #222222 !important;
    padding-bottom: 12px !important;
        padding-top: 12px !important;
}




.dropbtn{
    background: #ab2de1 !important;
    padding: 6px 7px !important;
    border: none !important;
}
.dropbtn:focus{
    box-shadow: none !important;
}
.navbar-nav .dropdown-menu {
    box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 20%);
    background: #ffffff;
    top: 80%;
    position: absolute;
    right: 2px !important;
    left: auto !important;
    border: 1px solid #ffffff;
    padding: 12px !important;
}
.link{
    text-decoration: none;
    color: black !important;
}
.link:hover{
    color: black !important;
}
.DrpAdmin:active{
    background: #e3c1f1 !important;
}

.navLink.active {
    border-bottom: 2px solid #ab2de1 ; /* Replace with your desired color */
    padding-right: 25px !important;
    padding-left: 25px !important;
    padding-bottom: 12px !important;
    padding-top: 12px !important;
  }