
/* Steps */
.step {
    position: relative;
    min-height: 1em;
    color: gray;
  }
  .step + .step {
    margin-top: 2.5em;
  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-left: 3.5em;
    padding-left: 1em;
  }
  .step.step-active {
    color: #4F46E5
  }
  .step.step-active .circle {
    background-color: #4F46E5;
  }
  
  /* Circle */
  .circle {
    background: gray;
    position: relative;
    width: 3.5em;
    height: 3.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Vertical Line */
  .circle:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 0px;
    left: 45%;
    height: 100%;
    width: 4px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: #4F46E5;
    z-index: 0;
  }
  .step:last-child .circle:after {
    display: none
  }
  
  /* Stepper Titles */
  .title {
    line-height:3.5em;
    font-weight: bold;
    font-size: 15px;
  }
  .caption {
    font-size: 0.8em;
  }

  .boxMile{
    background: #F7EAFC;
border-radius: 20px;
  }

  .pBox{
    color: #3E3B3F !important;
  }

  .boxMile:hover{
    box-shadow:  -12px 0 8px -4px #AA2AE1;;
  }