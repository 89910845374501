.resetSection{
    padding: 6%;
}

.logoImageSet{
    width: 45% !important;
}

.boldfont{
    font-weight: 600 !important;
}